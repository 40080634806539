import { Container, Row, Col } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container fluid>
      <Row className="py-3 py-md-5 mb-2 mb-md-4  text-center">
        <Col className="p-0">
          <h2 className="mb-4">Oops !</h2>
          <p>404 - PAGE NOT FOUND</p>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
