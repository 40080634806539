import { useContext, memo } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import { Card, Col } from "react-bootstrap";
import { nanoid } from "nanoid";
import Parser from "html-react-parser";
import { LanguageContext } from "../../contexts/LanguageContext";
import { convertToURLParams } from "../../utils/string";

const PromotionCard = ({ item }) => {
  const { language } = useContext(LanguageContext);

  return (
    <Col xs={6} sm={4} xl={3} className="d-flex align-items-stretch mb-4" key={nanoid()}>
      <Card className="Promotion-Card">
        <LazyLoad threshold={0}>
          <Link to={`/${language ? "en" : "th"}/promotions/${convertToURLParams(item.promotionName)}-p.${btoa(item.promotionID)}`}>
            <Card.Img variant="top" src={item.listingImage} />
          </Link>
        </LazyLoad>
        <Card.Body className="justify-content-between d-flex flex-column h-100">
          <small className="d-table mb-3 bg-red-1 txt-15 text-white p-1 px-2">
            {item.startDateTime}
          </small>
          {item.description.length > 60 ? (
            <p className="d-block text-grey-1 txt-15">
              {Parser(item.description.substring(0, 60))}...
            </p>
          ) : (
            <p className="d-block text-grey-1 txt-15">
              {Parser(item.description)}
            </p>
          )}

          <Link
            to={`/${language ? "en" : "th"}/promotions/${convertToURLParams(item.promotionName)}-p.${btoa(item.promotionID)}`}
            className="btn d-block mb-3 btn-login  btn-card-main w-100"
          >
            {language ? "More Info" : "ข้อมูลมากกว่านี้"}
          </Link>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default memo(PromotionCard);