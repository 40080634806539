import { useState, useEffect, useContext, useCallback, memo } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { VscClose } from "react-icons/vsc";
import * as yup from "yup";
import { FormField } from "../";
import { AuthContext } from "../../contexts/AuthContext";
import { LanguageContext } from "../../contexts/LanguageContext";
import { useValidation } from "../../hooks/validation";
import { getCityArea } from "../../api/cityArea";

const schema = yup.object().shape({
  addressName: yup.string().required("Required"),
  addressContactNumber: yup.string().required("Required"),
  addressStreet1: yup.string().required("Required"),
});

/**
 * 
 * @param {Object} props - Object props for the AddAddressModal component
 * @param {Boolean} props.showAddAddressModal - Toggle between show and hide the modal
 * @param {Function} props.setShowAddAddressModal - SetState action by react
 * @param {Function} props.submitForm - Form submission function from the parent component
 * @param {Array} props.citiesList - List of cities called by the API 
 * @returns {JSX.Element} - Returns the component Add Address Modal
 */
const AddAddressModal = ({ showAddAddressModal, setShowAddAddressModal, submitForm, citiesList }) => {
  /** -- Initializing form fields -- */
  const { errors, useField, validateForm } = useValidation(schema);
  const { addressNameField, setAddressNameField } = useField("addressName")
  const { addressContactNumberField, setAddressContactNumberField } = useField("addressContactNumber")
  const { addressStreet1Field, setAddressStreet1Field } = useField("addressStreet1")
  const [addressStreet2, setAddressStreet2] = useState("");
  const [city, setCity] = useState(-1);
  const [suburb, setSuburb] = useState(-1);
  const [postCode, setPostCode] = useState(0);
  /** -- End Initializing form fields */
  
  const { language } = useContext(LanguageContext);
  const { myUserID } = useContext(AuthContext);

  /** Initializing error messages */
  const [addressPhoneError, setAddressPhoneError] = useState(false);
  const [addressCityError, setAddressCityError] = useState(false);
  const [addressSuburbsError, setAddressSuburbsError] = useState(false);
  const [addressPostalCodeError, setAddressPostalCodeError] = useState(false);
  const [errormsg, setErrormsg] = useState(false);
  /** End Initializing error messages */

  /**
   * Set all any dependencies to default values -1 if is changed
   * e.g. Changing city -> Set default value for suburb and postCode
   *      Changing suburb -> Set default value for postCode
   *      Changing postCode -> Nothing
   *  
   * @param {Event} e - Event handler
   * @param {String} type - The type whether it is a city, suburb, or postCode
   */
  const handleFormChange = useCallback((e, type) => {
    e.preventDefault();
    if (type === "city") {
      setCity(e.target.value);
      setSuburb(-1);
      setPostCode(0);
      setAddressCityError(false);
    } else if (type === "suburb") {
      setSuburb(e.target.value);
      setPostCode(0);
      setAddressSuburbsError(false);
    } else if (type === "postCode") {
      setPostCode(e.target.value);
      setAddressPostalCodeError(false);
    }
    return;
  }, [city, suburb, postCode]);
  
  

  /**
   * Pre-submission to validate and construct the object
   * @param {Event} e - Event handler
   */
  const preSubmit = (e) => {
    e.preventDefault();
    if (
      validateAddress() &&
      validateForm("addressName", addressNameField) &&
      validateForm("addressContactNumber", addressContactNumberField) &&
      validateForm("addressStreet1", addressStreet1Field)
    ) {
      let accountDetails = {
        UsersID: parseInt(myUserID),
        AddressName: addressNameField,
        AddressPhone: addressContactNumberField,
        AddressLine_1: addressStreet1Field,
        AddressLine_2: addressStreet2,
        AddressStateID: 122,
        AddressCity: citiesList[language ? "EN" : "TH"][city].CityName,
        AddressSuburbs: citiesList[language ? "EN" : "TH"][city].AreaName[suburb].AreaName,
        Country: "Thailand",
        Postcode: postCode,
        AddressLanguage: language ? "EN" : "TH",
        ifDefault: false
      }
      submitForm(accountDetails);
    }
  }

  /**
   * To validate the rest of the address which are
   * City, area (suburbs), and post code
   * @returns {Boolean}
   */
  const validateAddress = () => {
    let check = true;
    if (city === -1) {
      setAddressCityError(true);
      check = false;
    }
    if (suburb === -1) {
      setAddressSuburbsError(true);
      check = false;
    }
    if (postCode === 0) {
      setAddressPostalCodeError(true);
      check = false;
    }
    return check;
  }

  return (
    <Modal
      show={showAddAddressModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          className="ProductDetail_Panel_Box"
          onClick={() => setShowAddAddressModal(false)}
        >
          <VscClose className="vs_close" />
        </div>
        <form className="container-lg data_wrap">
          <Row className="mb-3 text-center">
            <Col>
              <h2 className="mb-4 title-center">
                {language ? "Add Address" : "เพิ่มที่อยู่สำหรับจัดส่งสินค้า"}
              </h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={12} className="px-4 data_wrap">
              <Row className="mb-4">
                <Col sm={12} md={6}>
                  <FormField
                    value={addressNameField}
                    setValue={setAddressNameField}
                    error={errors.addressName}
                    labelText=""
                    placeholder={language ? "Recipient Name*" : "ชื่อผู้รับ*"}
                    fieldType="text"
                    inputType="Full Name*"
                  />
                </Col>
                <Col sm={12} md={6}>
                  <FormField
                    value={addressContactNumberField}
                    setValue={setAddressContactNumberField}
                    error={errors.addressContactNumber}
                    placeholder={language ? "Recipient Contact Number*" : "เบอร์โทรติดต่อ*" }
                    fieldType="number"
                    inputType="Contact Number*"
                  />
                  <span
                    className={`errormsg ${
                      addressPhoneError ? "d-block" : "d-none"
                    }`}
                  >
                    Please make sure your phone number is valid
                  </span>
                </Col>
                <Col sm={12}>
                  <FormField
                    value={addressStreet1Field}
                    setValue={setAddressStreet1Field}
                    error={errors.addressStreet1}
                    labelText=""
                    placeholder={language ? "Input address in EN*" : "ใส่ที่อยู่เป็นภาษาไทย*" }
                    fieldType="text"
                    inputType="Input address in EN*"
                  />
                </Col>
                <Col sm={12}>
                  <div>
                    <div className="input-relative mb-3">
                      <input
                        type={"text"}
                        placeholder={language ? "Input address in EN (Optional)" : "ใส่ที่อยู่เป็นภาษาไทย"}
                        value={addressStreet2}
                        className="block form-control"
                        onChange={(e) => setAddressStreet2(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Select
                    aria-label="Default select example"
                    className={`block form-control input-relative mb-3 ${
                      addressCityError && "border-danger border-2 border"
                    }`}
                    value={city}
                    onChange={(e) => handleFormChange(e, "city")}
                  >
                    <option value={-1} className="bg-grey-5">
                      Select City*
                    </option>
                    {!!citiesList && citiesList[language ? "EN" : "TH"]?.map((list, index) => (
                      <option key={index} value={index}>
                        {list.CityName}
                      </option>
                    ))}
                  </Form.Select>
                  {addressCityError && (
                    <p className="errormsg">Required</p>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <Form.Select
                    aria-label="Default select example"
                    className={`block form-control input-relative mb-3 ${
                      addressSuburbsError && "border-danger border-2 border"
                    }`}
                    value={suburb}
                    onChange={(e) => handleFormChange(e, "suburb")}
                  >
                    <option value={-1} className="bg-grey-5">
                      Select Area*
                    </option>
                    {(!!citiesList && city !== -1) && citiesList[language ? "EN" : "TH"][city].AreaName?.map((list, index) => (
                      <option key={index} value={index}>
                        {list.AreaName}
                      </option>
                    ))}
                  </Form.Select>
                  {addressSuburbsError && (
                    <p className="errormsg">Required</p>
                  )}
                </Col>
                <Col sm={12} md={6}>
                  <FormField
                    value={language ? "Thailand" : "ประเทศไทย"}
                    error={errors.addressCountry}
                    labelText=""
                    placeholder={language ? "Thailand" : "ประเทศ*"}
                    fieldType="text"
                    inputType="Country*"
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Form.Select
                    aria-label="Default select example"
                    className={`block form-control input-relative mb-3 ${
                      addressPostalCodeError && "border-danger border-2 border"
                    }`}
                    value={postCode}
                    onChange={(e) => handleFormChange(e, "postCode")}
                  >
                    <option value={0} className="bg-grey-5">
                      Select Postal Code*
                    </option>
                    {(!!citiesList && city !== -1 && suburb !== -1) && citiesList[language ? "EN" : "TH"][city].AreaName[suburb].Postcode?.map((list, index) => (
                      <option key={index} value={list}>
                        {list}
                      </option>
                    ))}
                  </Form.Select>
                  {addressPostalCodeError && (
                    <p className="errormsg">Required</p>
                  )}
                </Col>
              </Row>
              {!!errormsg &&
                <p className="errormsg">
                  Please make sure you fill in correct details
                </p>
              }
              <Row>
                <Col>
                  <button
                    onClick={preSubmit}
                    className="txt-20 link_ btn-card-main  d-table mx-auto wid-25 text-center"
                  >
                    {language ? "Add" : "เพิ่ม"}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default memo(AddAddressModal);