import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import { SHA256 } from "crypto-js";
import yupPassword from "yup-password";
import { createAccount } from "../../api/account";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AuthContext } from "../../contexts/AuthContext";
import { useValidation } from "../../hooks/validation.js";
import FormField from "../form/FormField.js";

const CreatePassword = () => {
  const navigate = useNavigate();

  const { language } = useContext(LanguageContext);
  const { emailOrPhone } = useContext(AuthContext);

  //1. Define form schema
  const yup = require("yup");
  require("yup-password")(yup);
  yupPassword(yup);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required()
      .min(
        8,
        "Password must be 8 characters long and contain a combination of alphanumeric characters and special symbols."
      )
      .minNumbers(1, "Password must be contain one numeric characters")
      .minSymbols(
        1,
        `Password must be contain at least one special symbol, etc: !"#$%&'()*+,-./:;<=>?@^`
      ),
    confirmPassword: yup
      .string()
      .required()
      .min(
        8,
        "Password must be 8 characters long and contain a combination of alphanumeric characters and special symbols."
      )
      .minNumbers(1, "Password must be contain one numeric characters")
      .minSymbols(
        1,
        `Password must be contain at least one special symbol, etc: !"#$%&'()*+,-./:;<=>?@^`
      ),
  });

  //2. Initialize the useValidation hook
  const { errors, useField, validateForm } = useValidation(schema);

  //3. Define form fields
  const { passwordField, setPasswordField } = useField("password");
  const { confirmPasswordField, setConfirmPasswordField } =
    useField("confirmPassword");
  const [errormsg, setErrormsg] = useState(false);

  const confirmHandler = async (e) => {
    e.preventDefault();
    const isFormValid = validateForm();

    if (passwordField !== confirmPasswordField) return;
    if (isFormValid) {
      let hashDigest = SHA256(passwordField);

      let data;
      if (emailOrPhone.includes("@")) {
        data = { Email: emailOrPhone, Password: hashDigest.toString() };
      } else {
        data = { PhoneNumber: emailOrPhone, Password: hashDigest.toString() };
      }

      const { status, userID } = await createAccount(data);
      if (status == "200" && userID != "0") {
        localStorage.setItem("UserID", userID);
        setTimeout(() => navigate((language ? "/en" : "/th") + "/user/confirmation"), 300);
      }
    }
  };

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/account_bg.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white">
              <form className="container-lg">
                <Row className="mb-3 text-center justify-content-center p-5">
                  <Col sm={12} md={6}>
                    <Link to="/signup">
                      <BsArrowLeftShort className="form_ico_back" />
                    </Link>

                    <h2 className="mb-4">
                      {language ? "Create Password" : "สร้างรหัสผ่าน"}
                    </h2>

                    <p>
                      {language
                        ? "Password must be 8 characters long and contain a combination of alphanumeric characters and special symbols."
                        : "รหัสผ่านต้องมีความยาว 8 ตัวอักษรขึ้นไป โดยควรประกอบด้วยตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ ตัวเลข และเครื่องหมายพิเศษผสมกัน"}
                    </p>

                    <section>
                      <FormField
                        value={passwordField}
                        setValue={setPasswordField}
                        error={errors.password}
                        labelText=""
                        placeholder="Password"
                        fieldType="Password"
                        inputType="Password"
                      />
                      <FormField
                        value={confirmPasswordField}
                        setValue={setConfirmPasswordField}
                        error={errors.confirmPassword}
                        labelText=""
                        placeholder="Confirm Password"
                        fieldType="Password"
                        inputType="Confirm Password"
                      />

                      {errormsg !== "" && (
                        <p className="errormsg">{errormsg}</p>
                      )}

                      <div
                        className="btn d-block mb-3 btn-card-sec  w-100"
                        onClick={confirmHandler}
                      >
                        {language ? "Confirm" : "ยืนยัน"}
                      </div>

                      <span className="d-block text-center txt-20 mb-4 mt-5">
                        By signing up, you agree to Mr. DIY's
                        <span className="d-md-flex justify-content-center mx-1">
                          <Link
                            to="/"
                            className="txt-20 link_ link_red mx-auto mx-md-0"
                          >
                            Terms of Service
                          </Link>
                          <span className="mx-1">&</span>
                          <Link
                            to="/"
                            className="txt-20 link_ link_red mx-auto mx-md-0"
                          >
                            Privacy Policy
                          </Link>
                        </span>
                      </span>
                    </section>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CreatePassword;
