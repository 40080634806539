import axios from "axios";
import { getAccessToken } from "../utils/jwt";

const taxInvoiceInfoClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/taxInvoiceInfo`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const saveTaxInfo = async (payload) => {
    const response = await taxInvoiceInfoClient.post('', payload)
    return response
}  

export const getDefaultTaxInfo = async (customerID) => {
  const response = await taxInvoiceInfoClient.post('/getDefault', {customerID})
  return response
}  

export const getTaxInfoByOrder = async (orderID) => {
  const response = await taxInvoiceInfoClient.post('/get', {orderID})
  return response
}  