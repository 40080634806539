import { useCallback } from "react";
import Pagination from "react-bootstrap/Pagination";
import { getTotalNumberOfPages } from "../../../utils/paginationUtils";
import "./Pagination.css";

/**
 * Renders a pgination component
 * @param {Object} props - The props for the PaginationGroup component.
 * @param {number} props.pageNumber - The current active page number. State from parent
 * @param {function} props.setPageNumber - A function to set the active page number. setState from parent
 * @param {number} props.totalItems - The total number of items in the pagination.
 * @param {number} props.totalItemsDisplay - The number of items to display per page.
 * @returns {JSX.Element} - The pagination component.
 */
const PaginationGroup = ({
  pageNumber,
  setPageNumber,
  totalItems,
  totalItemsDisplay
}) => {
  const handleNextClick = useCallback(() => {
    if (pageNumber === getTotalNumberOfPages(totalItems, totalItemsDisplay).length) return;
    setPageNumber(pageNumber + 1);
  }, [pageNumber, setPageNumber])

  const handlePrevClick = useCallback(() => {
    if (pageNumber === 1) return;
    setPageNumber(pageNumber - 1);
  }, [pageNumber, setPageNumber])

  const totalNumberOfPages = getTotalNumberOfPages(totalItems, totalItemsDisplay);
  const renderedPages = [];

  if (totalNumberOfPages.length <= 7) {
    renderedPages.push(...totalNumberOfPages);
  } else {
    if (pageNumber <= 4) {
      renderedPages.push(1, 2, 3, 4, 5, '...', totalNumberOfPages.length);
    } else if (pageNumber >= totalNumberOfPages.length - 3) {
      renderedPages.push(1, '...', totalNumberOfPages.length - 4, totalNumberOfPages.length - 3, totalNumberOfPages.length - 2, totalNumberOfPages.length - 1, totalNumberOfPages.length);
    } else {
      renderedPages.push(1, '...', pageNumber - 1, pageNumber, pageNumber + 1, '...', totalNumberOfPages.length);
    }
  }

  return (
    <>
      <Pagination className="justify-content-center">
        <Pagination.First onClick={() => { setPageNumber(1) }} />
        <Pagination.Prev onClick={handlePrevClick} />
        {renderedPages.map((num, idx) => (
          <Pagination.Item
            key={idx}
            active={num === pageNumber}
            onClick={() => {
              if (typeof num === 'number') {
                setPageNumber(num);
              }
            }}
          >
            {num}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={handleNextClick} />
        <Pagination.Last onClick={() => { setPageNumber(getTotalNumberOfPages(totalItems, totalItemsDisplay).length) }} />
      </Pagination>
    </>
  );
}

export default PaginationGroup;
