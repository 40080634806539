export const useFileDownload = () => {
  const downloadFile = (fileName, fileType, content) => {
    const fileBlob = b64toBlob(content, fileType);
    const url = URL.createObjectURL(fileBlob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();

    // Clean up the temporary URL
    URL.revokeObjectURL(url);
  };

  const b64toBlob = (b64Data, contentType) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  return { downloadFile };
};
