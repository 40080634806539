import { useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import { LanguageContext } from "../../contexts/LanguageContext";

const CreateUsername = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/account_bg.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white">
              <form className="container-lg">
                <Row className="mb-3 text-center justify-content-center p-5">
                  <Col sm={12} md={6}>
                    <Link to="/signup">
                      <BsArrowLeftShort className="form_ico_back" />
                    </Link>

                    <h2 className="mb-4">
                      {language ? "Create Username" : "Create Username"}
                    </h2>

                    <p>
                      {language
                        ? "Password must be 8 characters long and contain a combination of alphanumeric characters and special symbols."
                        : "รหัสผ่านต้องมีความยาว 8 ตัวอักษรขึ้นไป โดยควรประกอบด้วยตัวอักษรพิมพ์เล็ก พิมพ์ใหญ่ ตัวเลข และเครื่องหมายพิเศษผสมกัน"}
                    </p>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CreateUsername;
