import { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LazyLoad from "react-lazy-load";
import { LanguageContext } from "../../contexts/LanguageContext";

const ForgotPasswordSuccess = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/account_bg.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white p-5">
              <form className="container-lg  p-0 p-md-5">
                <Row className="mb-3">
                  <Col>
                    <div className="d-flex">
                      <LazyLoad threshold={0}>
                        <img
                          className="d-block img-fluid img-50 me-2 mb-2"
                          src={process.env.PUBLIC_URL + "/assets/ico_sucess.png"}
                          alt="basket"
                        />
                      </LazyLoad>
                      <h2 className="mb-4 text-green-1">
                        {language
                          ? "Password Reset Link is Sent!"
                          : "ส่งลิงค์รีเซ็ตรหัสผ่านแล้ว!"}
                      </h2>
                    </div>

                    <p className="mb-4 text-grey-1">
                      {language
                        ? "Please check your email and click on the password reset link to reset your password."
                        : "กรุณาตรวจสอบอีเมลของคุณและคลิกที่ลิงค์รีเซ็ตรหัสผ่านเพื่อรีเซ็ตรหัสผ่านของคุณ"}
                    </p>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ForgotPasswordSuccess;
