import { useEffect, useState, useContext } from "react";
import TagManager from "react-gtm-module";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Table, Modal } from "react-bootstrap";
import { nanoid } from "nanoid";
import { VscClose } from "react-icons/vsc";
import { Carousel } from "react-responsive-carousel";
import {
  Loader,
  AlertModal,
  Breadcrumb,
  AddToCartModal,
} from "../../components/";
import { LanguageContext } from "../../contexts/LanguageContext";
import { CartContext } from "../../contexts/CartContext";
import { AppContext } from "../../contexts/AppContext";
import { getAccessToken } from "../../utils/jwt";
import { getInventoryByID } from "../../api/inventory";
import { addWishlistItem } from "../../api/wishlist";
import { setCartItem } from "../../api/shoppingCart";
import "./ProductDetail.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProductDetail = () => {
  const customerDetails = getAccessToken({ parse: true });
  const { cartItems, setCartItems } = useContext(CartContext);
  const { setTitle } = useContext(AppContext);
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const hasVariance = product.variance && product.variance.length > 0;
  const [active, setActive] = useState("");
  const [isAddToCartModalOpen, setIsAddToCartModalOpen] = useState(false);
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
  const [errorCartlistPanel, setErrorCartlistPanel] = useState(false);
  const [selectedProductID, setSelectedProductID] = useState("");
  const [quantityToAdd, setQuantityToAdd] = useState(1);
  const [show, setShow] = useState(false);
  const [showIndex, setShowIndex] = useState(0);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [productStocks, setProductStocks] = useState(0);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-MMFHWKW",
      events: {
        view_item: "view_item",
      },
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    const getInventory = async () => {
      setLoading(true);
      const { inventory, status } = await getInventoryByID({
        inventoryID: atob(id),
      });

      if (inventory !== undefined || inventory !== "" || status === 200) {
        setProduct(inventory);
        setProductStocks(inventory.stock);
        setTitle(inventory.name[language ? "EN" : "TH"]);
        setLoading(false);
      }
    };
    getInventory();
  }, []);

  //select variacne
  const selectClick = (e, item, itemAmount) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedProductID(item);
    setProductStocks(itemAmount);
    setActive(item);
  };

  const handleShow = (e) => {
    setShowIndex(e);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const addToCart = () => {
    if (customerDetails === null) {
      setLoading(true);
      setTimeout(() => navigate((language ? "/en" : "/th") + "/user"), 1000);
      return;
    }
    setIsAddToCartModalOpen(true);
  };

  const addToWishlist = async (id) => {
    if (hasVariance && id === "") {
      setAlert(true);
      setAlertMessage("Please select a variance.");
      return;
    }

    if (customerDetails === null) {
      setLoading(true);
      setTimeout(() => navigate((language ? "/en" : "/th") + "/user"), 1000);
      return;
    }
    try {
      setLoading(true);

      const dataLayer = {
        event: "add_to_wishlist",
        ecommerce: {
          currency: "THB",
          value: product.discountedPrice,
          items: [
            {
              ...product,
              quantity: parseInt(quantityToAdd),
            },
          ],
        },
      };
      TagManager.dataLayer({ dataLayer });

      const response = await addWishlistItem({
        productID: id,
        customerID: customerDetails?.customerID,
      });

      if (response.status === 200) {
        setTimeout(
          () => navigate((language ? "/en" : "/th") + "/wishlist"),
          1000
        );
      } else {
        setAlert(true);
        setAlertMessage(response?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const triggerCartList = async (id) => {
    setIsAddToCartModalOpen(false);
    setIsCartSidebarOpen(true);

    const dataLayer = {
      event: "add_to_cart",
      ecommerce: {
        currency: "THB",
        value: product.discountedPrice,
        items: [
          {
            ...product,
            quantity: parseInt(quantityToAdd),
          },
        ],
      },
    };
    TagManager.dataLayer({ dataLayer });

    const isMatchCartProduct = cartItems.find((item) => item.id == id);

    let data = {
      customerID: customerDetails?.customerID,
      productID: id,
      quantity: isMatchCartProduct
        ? parseInt(isMatchCartProduct?.quantity) + parseInt(quantityToAdd)
        : parseInt(quantityToAdd),
    };

    await setCartItem(data).then((res) => {
      if (res.ErrorMsg) {
        window.alert("Please select a variance.");
        return;
      }
      let volumeDiscounts = res.data;
      let volumeDiscountValue = 0;
      if (isMatchCartProduct) {
        setCartItems((cartItems) => {
          volumeDiscounts?.map((a) => {
            if (id == a.SKUID) {
              volumeDiscountValue =
                parseFloat(a.VolumeDiscountValue) / 100 || 0;
            }
          });
          return cartItems.map((item) =>
            item.id === isMatchCartProduct.id
              ? {
                  ...item,
                  quantity: item.quantity + parseInt(quantityToAdd),
                  VolumeDiscountValue: volumeDiscountValue,
                }
              : item
          );
        });
      } else {
        setCartItems((cartItems) => {
          volumeDiscounts?.map((a) => {
            if (id == a.SKUID) {
              volumeDiscountValue =
                parseFloat(a.VolumeDiscountValue) / 100 || 0;
            }
          });
          return [
            ...cartItems,
            {
              ...product,
              quantity: parseInt(quantityToAdd),
              VolumeDiscountValue: volumeDiscountValue,
            },
          ];
        });
      }
    });
  };

  const minusQuantity = () => {
    if (quantityToAdd !== 1) {
      setQuantityToAdd((prev) => prev - 1);
    } else {
      setQuantityToAdd(1);
    }
  };
  const plusQuantity = () => {
    if (quantityToAdd >= 1) {
      setQuantityToAdd((prev) => prev + 1);
    } else {
      setQuantityToAdd(1);
    }
  };

  useEffect(() => {
    if (quantityToAdd <= productStocks) {
      setErrorCartlistPanel(false);
    } else {
      setErrorCartlistPanel(true);
    }
  }, [quantityToAdd, isAddToCartModalOpen]);

  const productVariants = () => {
    return (
      <>
        {product.variance?.map((item, index) => (
          <div
            key={nanoid()}
            name={index}
            value={index}
            className={
              active === item.SKUID
                ? "border-focus variance_box link_"
                : "variance_box link_ "
            }
            onClick={(e) => selectClick(e, item.SKUID, item.SKUAvailableItems)}
          >
            {item.SKUVariantType}
          </div>
        ))}
      </>
    );
  };

  const handleAddToCart = () => {
    if (hasVariance) {
      if (!selectedProductID) {
        window.alert("Please select a variance.");
        return;
      }

      triggerCartList(selectedProductID);
    } else {
      triggerCartList(product.id);
    }
  };

  return (
    <>
      {loading && <Loader status={loading} />}

      <div
        className={
          isAddToCartModalOpen || isCartSidebarOpen
            ? "fullPage showing"
            : "fullPage"
        }
      ></div>

      <AlertModal
        show={alert}
        handleClose={() => setAlert(!alert)}
        message={alertMessage}
      />

      <AddToCartModal
        isOpen={isAddToCartModalOpen}
        setOpen={setIsAddToCartModalOpen}
        quantityToAdd={quantityToAdd}
        setQuantityToAdd={setQuantityToAdd}
        product={product}
        handleAddToCart={handleAddToCart}
        minusQuantity={minusQuantity}
        plusQuantity={plusQuantity}
        productStocks={productStocks}
        productVariants={productVariants}
        errorCartlistPanel={errorCartlistPanel}
      />

      <div className="ProductDetail_Page">
        <Modal
          size="lg"
          centered
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <Modal.Body>
            {product.image && (
              <>
                <div className="ProductDetail_Panel_Box" onClick={handleClose}>
                  <VscClose className="vs_close" />
                </div>
                <img
                  src={product.image[showIndex]?.original}
                  alt=""
                  className="img-fluid w-100"
                  onClick={handleClose}
                />
              </>
            )}
          </Modal.Body>
        </Modal>

        {/* Cart Sidebar */}
        <div
          className={
            isCartSidebarOpen ? "Cartlist_Panel showing" : "Cartlist_Panel"
          }
        >
          <div
            className="Cartlist_Panel_Box"
            onClick={() => setIsCartSidebarOpen(false)}
          >
            <VscClose className="vs_close" />
          </div>
          <div className="data_wrap pt-5">
            <div className="py-4">
              <h4>
                {language ? (
                  <>Successfully added to cart!</>
                ) : (
                  <>เพิ่มสินค้าลงตะกร้าเรียบร้อยแล้ว!</>
                )}
              </h4>
              <Link
                to={`/${language ? "en" : "th"}/cart`}
                className="text-orange-1 text-decoration-underline"
              >
                {language ? <>Continue to cart</> : <>ไปยังตะกร้า!</>}
              </Link>
            </div>

            <hr />

            <h5 className="py-3">
              {language ? (
                <>
                  <span className="">({cartItems?.length})</span> Items in cart
                </>
              ) : (
                <>
                  <span className="">({cartItems?.length})</span> สินค้าในตะกร้า
                </>
              )}
            </h5>

            <>
              <div
                key={nanoid()}
                className=" py-2 cart_Row d-flex flex-column  border-cart-divide"
                style={{ borderTop: "transparent" }}
              >
                {cartItems?.map((item) => (
                  <div key={nanoid()} className="d-flex mb-3 ">
                    <div className="me-3 me-md-4">
                      {item.image !== undefined && (
                        <>
                          <img
                            src={item?.image[0].original}
                            alt=""
                            className="img-thumbnail"
                          />
                        </>
                      )}
                    </div>

                    <div className="w-100">
                      <div className="pt-2 w-100  mb-3">
                        <p className="txt-bold ">
                          {language ? (
                            <>{item.name?.EN}</>
                          ) : (
                            <>{item.name?.th}</>
                          )}
                        </p>

                        <div className="d-block">
                          <div className="bg-grey-5 d-table rounded-1 px-2 mb-2">
                            {language ? (
                              <> Qty : {item?.quantity}</>
                            ) : (
                              <> จำนวน : {item?.quantity}</>
                            )}
                          </div>
                        </div>

                        <div className="d-flex text-end w-100 justify-content-end">
                          <div className="d-block">
                            {item.discountRate !== "25%" ? (
                              <h3 className="text-price txt-30 mb-3">
                                <sub>THB</sub>
                                {(item.VolumeDiscountValue > 0
                                  ? item.originalPrice *
                                    (1 - item.VolumeDiscountValue) *
                                    parseInt(item.quantity)
                                  : item.discountedPrice *
                                    parseInt(item.quantity)
                                ).toFixed(2)}
                              </h3>
                            ) : (
                              <>
                                <div className="d-block">
                                  <h3 className="text-price text-red-1 txt-30 mb-2 text-end">
                                    <sub>THB</sub>
                                    {(item.VolumeDiscountValue > 0
                                      ? item.originalPrice *
                                        (1 - item.VolumeDiscountValue) *
                                        parseInt(item.quantity)
                                      : item.discountedPrice *
                                        parseInt(item.quantity)
                                    ).toFixed(2)}
                                  </h3>

                                  <h4 className="text-price text-grey-1 txt-20 mb-2 text-normal">
                                    <sub className="txt-stroke text-normal">
                                      THB
                                    </sub>

                                    <span className={`txt-stroke`}>
                                      {(
                                        item.originalPrice *
                                        parseInt(item.quantity)
                                      ).toFixed(2)}
                                    </span>
                                  </h4>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          </div>
        </div>

        <Container fluid="xxl" className="mb-0">
          <Row className="data_wrap pt-5 pb-0">
            <Breadcrumb product={product} />
          </Row>

          <Row className="p-2 p-md-5 mb-2 mb-md-0">
            <Col xs={12} md={6} className="">
              <Col className="">
                <div className="product_carousel">
                  <Carousel
                    selectedItem={0}
                    thumbWidth={100}
                    autoFocus={true}
                    useKeyboardArrows={false}
                    showThumbs={true}
                    swipeable={true}
                    interval="100"
                    transitionTime="100"
                  >
                    {product?.image?.map((item, index) => (
                      <div
                        key={nanoid()}
                        className="rotate_img"
                        onClick={() => handleShow(index)}
                      >
                        <img src={item?.original} alt={product.name.TH} />
                        <div className="soldout-wrapper">
                          {productStocks <= 0 && (
                            <div className="soldout">
                              {language ? "Sold Out" : "สินค้าหมด"}
                            </div>
                          )}
                        </div>
                        <div key={nanoid()}>
                          {product.discount === "" || product.discount === 0 ? (
                            <>
                              {language ? (
                                <>
                                  {product.label.EN === "New" && (
                                    <span className="new_label_end">New</span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {product.label.EN === "New" && (
                                    <span className="new_label_end">ใหม่</span>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <span
                                className={`discount_label ${
                                  product.label.EN === "" ? "d-none" : "d-block"
                                }`}
                              >
                                {product.discount}
                              </span>
                              {language ? (
                                <>
                                  {product.label.EN === "New" && (
                                    <span className="new_label">New</span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {product.label.EN === "New" && (
                                    <span className="new_label">ใหม่</span>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </Col>
            </Col>
            <Col xs={12} md={6}>
              <Col className="px-0">
                <div className="data_wrap">
                  <h2>{product?.name?.[language ? "EN" : "TH"] ?? ""}</h2>

                  <div className="justify-content-md-between d-md-flex align-md-items-baseline product_infoTAG">
                    <p className="mb-2">
                      {language ? "Product Code" : "รหัสสินค้า"}: {product.code}
                    </p>
                  </div>

                  <hr />

                  <div className="pt-3 pt-md-4 ">
                    <div className="d-flex align-items-baseline">
                      {!product.discountRate ? (
                        <h2 className="text-price-h2  mb-3 me-2">
                          <sub className="sub-price-h2">THB</sub>
                          {product?.discountedPrice}
                        </h2>
                      ) : (
                        <div className="d-block d-md-flex align-items-md-baseline">
                          <h2 className="text-price-h2 text-red-1  mb-3 me-2">
                            <sub className="sub-price-h2">THB</sub>
                            {product?.discountedPrice}
                          </h2>

                          <h4
                            className={`text-price text-grey-1 txt-20 mb-2 text-normal ${
                              product.discountRate !== null
                                ? "d-block"
                                : "d-none"
                            }`}
                          >
                            <sub className="txt-stroke text-normal">THB</sub>
                            <span className="txt-stroke">
                              {product?.originalPrice}
                            </span>
                          </h4>
                        </div>
                      )}
                    </div>

                    <div className="w-100 d-inline-flex my-2 my-md-3">
                      {product?.variance?.length > 0 && (
                        <div className="me-2 d-table">
                          <p className="mt-2">
                            {language ? "Variance" : "ตัวเลือกสินค้า"}
                          </p>
                        </div>
                      )}

                      <div className="w-100">
                        <div className="variance_ul">{productVariants()}</div>
                      </div>
                    </div>

                    <div className="d-lg-inline-flex align-items-center mt-3 gap-3">
                      {productStocks <= 0 ? (
                        <div
                          className={`btn d-table mb-3 btn-card-sec disabled`}
                          id="addCart"
                        >
                          {language ? "Add To Cart" : "เพิ่มลงตะกร้า"}
                        </div>
                      ) : (
                        <div
                          className={`btn d-table mb-3 btn-card-sec`}
                          id="addCart"
                          onClick={addToCart}
                        >
                          {language ? "Add To Cart" : "เพิ่มลงตะกร้า"}
                        </div>
                      )}

                      <div
                        className="btn d-table mb-3 btn-card-thi"
                        id="addWishlist"
                        onClick={() =>
                          addToWishlist(
                            hasVariance ? selectedProductID : product.id
                          )
                        }
                      >
                        {language ? "Add To Wishlist" : "Add To Wishlist"}
                      </div>

                      <p className="mx-3">
                        {language ? (
                          <span>
                            only{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {productStocks}{" "}
                            </span>
                            left
                          </span>
                        ) : (
                          <span>
                            เหลืออีกแค่{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {productStocks}{" "}
                            </span>
                            ชิ้น
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>

        <div className="bg-grey-6">
          <Container fluid="xxl">
            <Row className="py-4 py-md-5 px-3 px-md-5">
              <Col xs={12} md={7} className="mb-3 mb-md-5">
                <div>
                  <h3 className="title-left">
                    {language ? "Features" : "คุณสมบัติ"}
                  </h3>
                  <ul className="features_ul">
                    {product?.features?.[language ? "EN" : "TH"].map(
                      (feature, index) => (
                        <li key={index}>{feature[index]}</li>
                      )
                    )}
                  </ul>
                </div>
              </Col>
              <Col xs={12} lg={5} className="mb-3 mb-md-5">
                <div className="px-0 px-md-5">
                  <h3 className="title-left">
                    {language ? "Specifications" : "รายละเอียดสินค้า"}
                  </h3>

                  <Table responsive className="border">
                    <tbody>
                      {product?.specs?.[language ? "EN" : "TH"].map((spec) => {
                        const [key, value] = Object.entries(spec)[0];

                        return (
                          <tr key={nanoid()}>
                            <td className="text-wrap px-3">{key}</td>
                            <td className="text-wrap px-3">{value || "-"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
