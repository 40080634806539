import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import "./LoginRegister.css";

const AuthRedirect = () => {
  const { myUserID } = useContext(AuthContext);
  return myUserID ? <Navigate to="/"></Navigate> : <Outlet />;
}

export default AuthRedirect;
