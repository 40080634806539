import { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AppContext } from "../../contexts/AppContext";
import { getOrderStatus } from "../../api/order";
import { getTaxInfoByOrder } from "../../api/taxInvoiceInfo";
import { useQuery } from "../../hooks/route";
import { Loader } from "../../components";
import { sendFullTaxInvoiceEmail } from "../../api/enginemailer";
import "../../components/login/LoginForm.css";

const OrderConfirmation = () => {
  //state
  const query = useQuery();
  const orderID = query.get("Ref");
  const [orderConfirmed, setOrderConfirmed] = useState(null);
  const [loading, setLoading] = useState(false);

  const { language } = useContext(LanguageContext);
  const { setTitle, setDescription } = useContext(AppContext);

  //order status api
  useEffect(() => {
    const checkOrderStatus = async () => {
      setLoading(true);
      const { status, order } = await getOrderStatus({ orderID });
      var emailData = await getTaxInfoByOrder(order?.OrderID);
      if (order.OrderStatusID === 9 && emailData.data) {
        let payload = {
          orderId: order?.OrderID,
          CustomerType: emailData.data.Tax_CustomerType,
          Fullname: emailData.data.Tax_FullName,
          TaxIdField: emailData.data.Tax_CustomerTaxID,
          CustomerPhone: emailData.data.Tax_CustomerPhone,
          CustomerEmail: emailData.data.Tax_CustomerEmail,
          AddressLine_1: emailData.data.Tax_CustomerAddress,
          Postcode: emailData.data.Tax_Postcode,
          AddressCity: emailData.data.Tax_CustomerAddressCity,
          AddessSuburbs: emailData.data.Tax_CustomerAddressSuburbs,
          Country: "Thailand",
        };
        sendFullTaxInvoiceEmail(payload);
      }

      setOrderConfirmed({
        orderNumber: order?.OrderID ?? "-",
        trackingNumber: order?.OrderTrackingNumber || "-",
        contact: order?.Contact ?? "-",
        shippingAddress:
          [
            order?.AddressLine_1,
            order?.AddressLine_2,
            order?.AddressZip,
            order?.City,
          ]
            .filter(Boolean)
            .map((item) => item.trim())
            .join(",") ?? "-",
        totalPrice: order?.OrderTotalPrice ?? 0,
        paymentMethod: order?.OrderPaymentMethod ?? "-",
      });
      setTitle("Order Confirmation");
      setDescription("");
      setLoading(false);
    };
    checkOrderStatus();
  }, []);

  return (
    <div className="bg-grey-6 py-5">
      {loading && <Loader status={loading} />}
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white p-5">
              <form className="container-lg  p-0 p-md-5">
                <Row className="mb-3">
                  <Col>
                    <div className="d-flex">
                      <LazyLoad threshold={0}>
                        <img
                          className="d-block img-fluid img-50 me-2 mb-2"
                          src={
                            process.env.PUBLIC_URL + "/assets/ico_sucess.png"
                          }
                          alt="basket"
                        />
                      </LazyLoad>
                      <h2 className="mb-4 text-green-1">
                        {language
                          ? "Your Order is Confirmed!"
                          : "คำสั่งซื้อของคุณได้รับการยืนยันแล้ว"}
                      </h2>
                    </div>

                    <p className="mb-4 text-grey-1">
                      {language
                        ? "We've accepted your order, and we're getting it ready. Come back to this page for updates on your shipment status."
                        : "เราได้รับคำสั่งซื้อของคุณแล้ว และกำลังเตรียมส่งของให้คุณ หากคุณต้องการตรวจสอบสถานะการจัดส่ง สามารถกลับมาติดตามได้ที่หน้านี้"}
                    </p>
                  </Col>
                </Row>

                <hr />

                <Row className="mb-3">
                  <Col>
                    <Table responsive>
                      <tbody className="border-none">
                        <tr>
                          <td className="w-35">
                            {language ? "Order Number" : "หมายเลขคำสั่งซื้อ"}
                          </td>
                          <td>{orderConfirmed?.orderNumber}</td>
                        </tr>
                        <tr>
                          <td className="w-35">
                            {language
                              ? "Tracking Number"
                              : "หมายเลขติดตามพัสดุ"}
                          </td>
                          <td>{orderConfirmed?.trackingNumber}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                <hr />

                <Row className="mb-3">
                  <Col>
                    <h4 className="my-4">
                      {language ? "Customer Information" : "ข้อมูลลูกค้า"}
                    </h4>
                    <Table responsive>
                      <tbody className="border-none">
                        <tr>
                          <td className="w-35">
                            {language
                              ? "Contact Information"
                              : "ข้อมูลการติดต่อ"}
                          </td>
                          <td>{orderConfirmed?.contact}</td>
                        </tr>
                        <tr>
                          <td className="w-35">
                            {language
                              ? "Shipping Address"
                              : "ที่อยู่สำหรับจัดส่งสินค้า"}
                          </td>
                          <td>{orderConfirmed?.shippingAddress}</td>
                        </tr>
                        <tr>
                          <td className="w-35">
                            {language ? "Payment method" : "วิธีการชำระเงิน"}
                          </td>
                          <td>
                            {language
                              ? orderConfirmed?.paymentMethod || "-"
                              : orderConfirmed?.paymentMethod || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-35">
                            {language
                              ? "Total Payment"
                              : "รวมจำนวนเงินทั้งสิ้น"}
                          </td>
                          <td>
                            <p className="text-price  mb-3">
                              <sub>THB</sub>
                              {orderConfirmed?.totalPrice}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="/">
                              <button className="link_ btn-card-sec d-table mx-auto">
                                {language
                                  ? "Back to Main Page"
                                  : "กลับสู่หน้าหลัก"}
                              </button>
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/${language ? "en" : "th"}/account#history`}
                            >
                              <button className="link_ btn-card-sec d-table mx-auto">
                                {language
                                  ? "View Order History"
                                  : "ดูประวัติการสั่งซื้อ"}
                              </button>
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrderConfirmation;
