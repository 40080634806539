/**
 * Capitalize the first letter of the string
 * @param {string} string - The string to be capitalized
 * @returns {string} The capitalized string
 */
export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Convert texts to be friendly-url parameterized
 * @param {string} text
 * @returns {string}
 */
export const convertToURLParams = (text) => {
  if (!text) return;
  const urlParams = text
    .toLowerCase()
    .replace(/[/\s+]/g, "-")
    .replace(/[^a-z0-9ก-๙-.]/g, "")

  return urlParams;
}