import { useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { CookiesContext } from "../../contexts/CookiesContext";
import "./Cookies.css";

// i18n
const cookiePolicy = {
  'en': 'You can click on the "Accept Cookies" option to allow the use of analytics cookies specified in the ',
  'th': 'คุณสามารถกด "ยอมรับ" เพื่อยินยอมให้ใช้คุกกี้เพื่อเก็บรวบรวมข้อมูลเกี่ยวกับการใช้เว็บไซต์ของคุณ และทำให้เราสามารถพัฒนาวิธีการทำงานของเว็บไซต์ให้ดีขึ้น คุณสามารถเรียนรู้เพิ่มเติมเกี่ยวกับการใช้งานคุกกี้ของเราได้ที่ นโยบายการใช้คุกกี้ '
}

const cookiePolicyLink = {
  'en': <a className="cookiePolicyAnchor" href="/en/pages/Privacy-Policy">Cookie Policy</a>,
  'th': <a className="cookiePolicyAnchor" href="/th/pages/Privacy-Policy">นโยบายการใช้คุกกี้</a>,
}

function CookieButton({action, language, cookies, onClick}) {
  const declineButtonText = {
    'en': 'Decline Cookies',
    'th': 'ปฏิเสธ'
  }
  const acceptButtonText = {
    'en': 'Accept Cookies',
    'th': 'ยอมรับ'
  }
  return(
    <a href="#" className={"btn " + "btn-" + (action === "accept" ? "primary" : "secondary")} onClick={onClick}>{action === "decline" ? declineButtonText[language]: acceptButtonText[language]}</a>
  )
}

const Cookies = () => {    
  const { language } = useContext(LanguageContext);
  const lang = language ? 'en' : 'th'
  const { cookies, handleCookies } = useContext(CookiesContext);

    return (
      <div className="cookie-bar-wrap show p-3 text-left" >
        <div className="row">
          <div className="col-md-6 col-sm-12 my-3">
            {cookiePolicy[lang]}
            {cookiePolicyLink[lang]}
          </div>
          <div className="col-md-6 col-sm-12 py-2 d-flex align-items-center">
            <CookieButton action='decline' language={lang} cookies={cookies} onClick={handleCookies}/>
            <CookieButton action='accept' language={lang} cookies={cookies} onClick={handleCookies}/>
          </div>
        </div>
      </div>
    );
  }

export default Cookies
