import axios from "axios";

const promotionClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/promotions`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer Zmd2LnRlbmVvdGVjaC5uZXQ=",
  },
});

export const getPromotions = async ({ page, showedStatus, itemsPerPage, language }) => {
  const { data } = await promotionClient.get("", {
    params: {
      page: page,
      status: showedStatus,
      pageSize: itemsPerPage,
      language: language
    },
  });

  return {
    data: data.promotions.data,
    status: data.showedStatus,
    length: data.promotions.totalPages,
  };
};

export const getPromotionByID = async (promotionID) => {
  const { data } = await promotionClient.get(`/${promotionID}`);

  return {
    status: data.status,
    data: data.promotion,
  };
};
