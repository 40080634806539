import { memo } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import { NavLink } from "react-router-dom";
import { convertToURLParams } from "../../utils/string";

function ProductItem({
  product,
  updateCartItemQuantity,
  handleCartItemSelect,
  selectedCartItems,
  error,
}) {
  const hasDiscount = product.originalPrice - product.discountedPrice > 0;
  const { language } = useContext(LanguageContext);
  return (
    <div>
      <div
        id={"cart_" + product.code}
        className=" py-2  cart_Row d-flex justify-content-between border-cart-divide"
      >
        <div className="cart_Row">
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="cus_checkbox px-2 form-check-input"
              onChange={(e) => handleCartItemSelect(e, product.id)}
              checked={selectedCartItems.includes(product.id)}
            />
          </div>
        </div>
        <div className="cart_Row me-3">
          <NavLink to={`${language ? "/en" : "/th"}/${convertToURLParams(product.category)}/${convertToURLParams(product.name[language ? "EN" : "TH"])}-i.${btoa(product.id)}`}>
            <img
              src={typeof product.image === "string" ? product.image : product.image[0].original}
              alt={product.name?.EN || product.name.TH}
              className="img-thumbnail"
            />
          </NavLink>
        </div>

        <div className="cart_Row  w-75">
          <div className="d-md-flex pt-2 w-100 justify-content-between mb-3">
            <h5 className="wid-50 mb-3">
              {language ? <>{product.name.EN}</> : <>{product.name.TH}</>}
            </h5>

            <div className="d-flex align-items-start text-end w-100 justify-content-between">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <span
                    className="link_ txt-30 input-20  d-table align-items-center justify-content-center m-2 m-md-3"
                    onClick={async () =>
                      await updateCartItemQuantity(
                        product.id,
                        parseInt(product.quantity) - 1
                      )
                    }
                  >
                    {" "}
                    -{" "}
                  </span>

                  <div className="bg-grey-5 px-3 py-3 px-md-4 py-md-3">
                    {product.quantity}
                  </div>

                  <span
                    className="link_ txt-30 input-20  d-table align-items-center justify-content-center m-2 m-md-3"
                    onClick={async () =>
                      await updateCartItemQuantity(
                        product.id,
                        parseInt(product.quantity) + 1
                      )
                    }
                  >
                    {" "}
                    +{" "}
                  </span>
                </div>
                {error.id === product.id && (
                  <p className="errormsg text-center txt-15">
                    {language
                      ? "You have reached the maximum quantity available for this item"
                      : "คุณถึงจำนวนสูงสุดที่มีสำหรับรายการนี้แล้ว"}
                  </p>
                )}
              </div>

              <div className="d-block">
                {!hasDiscount ? (
                  <h3 className="text-price txt-30 mb-3">
                    <sub>THB</sub>
                    {(product.VolumeDiscountValue > 0 ? product.originalPrice * (1 - (product.VolumeDiscountValue || 0)) * product.quantity : product.discountedPrice * product.quantity).toFixed(2)}
                  </h3>
                ) : (
                  <div className="d-block">
                    <h3 className="text-price text-red-1 txt-30 mb-2 text-end">
                      <sub>THB</sub>
                      {(product.VolumeDiscountValue > 0 ? product.originalPrice * (1 - (product.VolumeDiscountValue || 0)) * product.quantity : product.discountedPrice * product.quantity).toFixed(2)}                    
                    </h3>

                    <h4 className="text-price text-grey-1 txt-20 mb-2 text-normal">
                      <sub className="txt-stroke text-normal">THB</sub>
                      <span className="txt-stroke">
                        {(product.originalPrice * product.quantity).toFixed(2)}
                      </span>
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ProductItem);
