import axios from "axios";

const authClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/auth`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const login = async ({ username, password }) => {
  const { data } = await authClient.post("/login", {
    username,
    password,
  });

  return data;
};
