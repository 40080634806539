import axios from "axios";
import TagManager from 'react-gtm-module';
import { getAccessToken } from "../utils/jwt";
import { checkAccessToken } from "./interceptor";

export const shoppingCartClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/shopping-cart`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

shoppingCartClient.interceptors.request.use(checkAccessToken);

export const getShoppingCartByID = async ({ userID, shippingAddressID, couponCodes }) => {

  const { data } = await shoppingCartClient.get("", {
    params: { customerID: userID, shippingAddressID: shippingAddressID, couponCode: couponCodes },
  });
  const { shoppingCart, status } = data;

  return {
    shoppingCart: shoppingCart,
    status: status,
  };
};

export const setCartItem = async (payload) => {
    const tagManagerArgs = {
        gtmId: 'GTM-MMFHWKW',
        events: {
            add_to_cart: 'add_to_cart'
        }
    }
    TagManager.initialize(tagManagerArgs)
  const { data } = await shoppingCartClient.post("", payload);

  return {
    data
  };
};

export const updateShoppingCart = async (cartID, payload) => {
    const tagManagerArgs = {
        gtmId: 'GTM-MMFHWKW',
        events: {
            add_to_cart: 'add_to_cart'
        }
    }
    TagManager.initialize(tagManagerArgs)
  const { data: u_data } = await shoppingCartClient.patch(
    `/${cartID}`,
    payload
  );
  return u_data;
};

export const removeSingleShoppingCart = async (cartID, payload) => {
  const tagManagerArgs = {
      gtmId: 'GTM-MMFHWKW',
      events: {
          remove_from_cart: 'remove_from_cart'
      }
  }
  TagManager.initialize(tagManagerArgs)
  const { data: response } = await shoppingCartClient.post('/', 
        payload);

  return {
    status: response.Status,
  };
};
