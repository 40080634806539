import { useContext, useEffect } from "react";
import {
  Hero,
  ProductCategories,
  Promotions,
  ProductTabSec as ProductTags,
  InfoSec as Info,
  Subscribe as Newsletter,
} from "../components";
import { AppContext } from "../contexts/AppContext";

const Home = () => {
  const { setTitle, setDescription } = useContext(AppContext);

  useEffect(() => {
    setTitle("");
    setDescription("");
  }, []);

  return (
    <>
      <Hero />
      <ProductCategories />
      <Promotions />
      <ProductTags />
      <Info />
      <Newsletter />
    </>
  );
};

export default Home;
