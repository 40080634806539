/**
 * To get the total number of pages to be displayed in scaled of the item and
 * the number of items to be displayed
 * @param {number} totalItems 
 * @param {number} totalItemsDisplay 
 * @returns {number[]}
 */
export const getTotalNumberOfPages = (totalItems, totalItemsDisplay) => {
    let numbers = [];
    for (let i = 1; i <= Math.ceil(totalItems/totalItemsDisplay); i++) {
        numbers.push(i);
    }
    return numbers;
}