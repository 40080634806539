import { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getProductsByTag } from "../../api/product";
import { PaginationGroup, ProductCard } from "../../components";
import { DISPLAY_ITEMS_PER_PAGE as TOTAL_ITEMS_DISPLAY } from "../../constants/config";
import { AppContext } from "../../contexts/AppContext";

const ProductByTag = () => {
  const { id } = useParams();
  const tagName = id
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  
  const [tag, setTag ] = useState('')
  const [products, setProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const { setTitle } = useContext(AppContext);

  useEffect(() => {
    const fetchProducts = async () => {
      const { products } = await getProductsByTag(tagName);

      setProducts([].concat(...Object.values(products)));
      setTag(Object.keys(products)[0])
    };
    setTitle(tagName);
    fetchProducts();
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="mb-2 mb-md-4">
          <Col className="p-0">
            <h1 className="text-center py-5">{tag}</h1>
          </Col>
        </Row>
      </Container>

      <Container fluid="xxl">
        <Row className="d-flex data_wrap">
          {!products || products.length <= 0 ? (
            <span className="errormsg text-center txt-30">No Result Found</span>
          ) : (
            <>
              {products?.slice(
                TOTAL_ITEMS_DISPLAY * (pageNumber-1),
                TOTAL_ITEMS_DISPLAY * pageNumber).map((product, idx) => (
                  <ProductCard key={idx} product={product} categoryName={product.category} />
              ))}
              <PaginationGroup 
                pageNumber={pageNumber} 
                setPageNumber={setPageNumber} 
                totalItems={products.length} 
                totalItemsDisplay={TOTAL_ITEMS_DISPLAY} 
              />
            </>
          )}
        </Row>
      </Container>
    </>
  );
}

export default ProductByTag;
