import { createContext, useState } from "react";

export const SortReviewContext = createContext("highest"); //context loginform

export const SortReviewProvider = ({ children }) => {
  const [rate, setRate] = useState("highest");

  const handlesortRate = (e) => {
    setRate(e.target.value);
  };

  return (
    <SortReviewContext.Provider value={{ rate, handlesortRate }}>
      {children}
    </SortReviewContext.Provider>
  );
};
