/**
 * Parses a date string in the format "DD/MM/YYYY HH:mm:ss AM/PM" and returns a JavaScript Date object.
 *
 * @param {string} dateString - The date string to be parsed.
 * @returns {Date} A JavaScript Date object representing the parsed date and time.
 * @throws {Error} If the input dateString is not in the correct format or is invalid.
 */
export const parseDate = (dateString) => {
    const parts = dateString.split(/[\/: ]/);
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript
    const year = parseInt(parts[2], 10);
    let hour = parseInt(parts[3], 10);
    const minute = parseInt(parts[4], 10);
    const second = parseInt(parts[5], 10);
    const meridiem = parts[6].toUpperCase();
  
    if (meridiem === "PM" && hour !== 12) {
      hour += 12;
    } else if (meridiem === "AM" && hour === 12) {
      hour = 0;
    }
  
    return new Date(year, month, day, hour, minute, second);
};