import axios from "axios";
import TagManager from "react-gtm-module";
import { getAccessToken } from "../utils/jwt";
import { checkAccessToken } from "./interceptor";

export const wishlistClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

wishlistClient.interceptors.request.use(checkAccessToken);

export const getWishlistItemsByCustomerID = async ({ customerID }) => {
  const { data } = await wishlistClient.get(`/customer/${customerID}/wishlist`);

  return {
    status: data.status,
    wishlist: data.wishlist,
  };
};

export const addWishlistItem = async ({ productID, customerID }) => {
  const tagManagerArgs = {
    gtmId: "GTM-MMFHWKW",
    events: {
      add_to_wishlist: "add_to_wishlist",
    },
  };
  TagManager.initialize(tagManagerArgs);
  const { data } = await wishlistClient.post("/wishlist", {
    productId: productID,
    customerId: customerID,
  });

  return data;
};

export const moveWishlistItemToCart = async ({ id, productID, customerID }) => {
  const { data } = await wishlistClient.post(`/wishlist/${id}`, {
    customerId: customerID,
    productId: productID,
  });

  return {
    status: data.status,
  };
};

export const removeWishlistItem = async (itemId) => {
  const response = await wishlistClient.delete(`/wishlist/${itemId}`);

  return response;
};
