import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/Inventory`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getInventoryByID = async ({ inventoryID }) => {
  const { data, status } = await client.get(`/${inventoryID}`);

  return {
    inventory: data,
    status,
  };
};
