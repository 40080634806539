import { useContext, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { VscClose } from "react-icons/vsc";
import { LanguageContext } from "../../contexts/LanguageContext";

const AddToCartModal = ({
  isOpen,
  setOpen,
  product,
  handleAddToCart,
  productVariants,
  minusQuantity,
  plusQuantity,
  quantityToAdd,
  setQuantityToAdd,
  productStocks,
  errorCartlistPanel,
}) => {
  const { language } = useContext(LanguageContext);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen && inputRef) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div
      className={isOpen ? "ProductDetail_Panel showing" : "ProductDetail_Panel"}
    >
      <div className="ProductDetail_Panel_Box" onClick={() => setOpen(false)}>
        <VscClose className="vs_close" />
      </div>

      <Container fluid="lg">
        <Row className="py-4 py-md-5 px-3 px-md-4 ProductDetail_Content justify-content-center">
          <Col xs={12} md={4}>
            <div className="data_wrap text-left text-md-center">
              {product.image && (
                <img
                  src={product.image[0]?.thumbnail}
                  alt=""
                  className="img-thumbnail"
                />
              )}
            </div>
          </Col>
          <Col xs={12} md={8}>
            <div className="data_wrap">
              <div className="w-100 d-inline-flex my-2 my-md-3">
                {product?.variance?.length > 0 && (
                  <div className="me-2 d-table">
                    <p className="mt-2">
                      {language ? "Variance" : "ตัวเลือกสินค้า"}
                    </p>
                  </div>
                )}

                <div className="w-100">
                  <div className="variance_ul">{productVariants()}</div>
                </div>
              </div>

              <div className="w-100 d-inline-flex my-2 my-md-3">
                <div className="me-2 d-table">
                  <p className="mt-2">{language ? "Quantity" : "จำนวน"}</p>
                </div>
                <div className="w-100">
                  <div className="d-flex align-items-center">
                    <span
                      className="link_ txt-30 input-20 d-flex align-items-center justify-content-center m-3"
                      onClick={minusQuantity}
                    >
                      {" "}
                      -{" "}
                    </span>

                    <div
                    // className="bg-grey-5 px-4 py-3"
                    >
                      <input
                        ref={inputRef}
                        type="number"
                        value={quantityToAdd}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                          maxWidth: "100px",
                          textAlign: "center",
                          border: "0",
                        }}
                        onChange={(e) =>
                          setQuantityToAdd(parseInt(e.target.value))
                        }
                        className="bg-grey-5 px-4 py-3"
                        max={productStocks}
                        min={1}
                      />
                    </div>

                    <span
                      className="link_ txt-30 input-20  d-flex align-items-center justify-content-center  m-3"
                      onClick={plusQuantity}
                    >
                      {" "}
                      +{" "}
                    </span>

                    <div className="mx-3">
                      {language ? (
                        <span>
                          only{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {productStocks}
                          </span>{" "}
                          left
                        </span>
                      ) : (
                        <span>
                          เหลืออีกแค่{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {productStocks}
                          </span>{" "}
                          ชิ้น
                        </span>
                      )}
                    </div>
                  </div>

                  {errorCartlistPanel && (
                    <p className="errormsg">
                      {language
                        ? "The requested quantity is not available"
                        : "ไม่มีปริมาณที่ร้องขอ"}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-4 mb-2">
                {errorCartlistPanel ? (
                  <div className="btn d-table mb-3 btn-card-sec w-100 disabled">
                    {language ? "Add To Cart" : "เพิ่มลงตะกร้า"}
                  </div>
                ) : (
                  <div
                    className="btn d-table mb-3 btn-card-sec w-100"
                    onClick={handleAddToCart}
                  >
                    {language ? "Add To Cart" : "เพิ่มลงตะกร้า"}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddToCartModal;
