import { getAccessToken } from "../utils/jwt";

export const checkAccessToken = (config) => {
  const bearerToken = config.headers["Authorization"];
  const token = bearerToken.replace(/Bearer /g, "");

  if (!token) {
    const newToken = getAccessToken();

    config.headers["Authorization"] = `Bearer ${newToken}`;
  }

  return config;
};
