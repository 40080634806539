import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/Banners`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getBanners = async (language) => {
  const { data, status } = await client.get("", {
    params: {
      language: language ? "en-US" : "th-TH"
    }
  });

  return {
    banners: data,
    status,
  };
};

export const getFooterBanners = async (language) => {
  const { data, status } = await client.get("/footer", {
    params: {
      language: language ? "en-US" : "th-TH",
    }
  });

  return {
    banners: data,
    status,
  };
};
