import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import OtpInput from "react-otp-input";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AuthContext } from "../../contexts/AuthContext";
import EmailClient from "../../api/emailVerification";
import PhoneClient from "../../api/phoneVerification";
import useRecaptcha from "../../hooks/recaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import "./LoginForm.css";

const VerificationForm = () => {
  const { language } = useContext(LanguageContext);
  const {
    emailOrPhone,
    handleVerify,
    verificationType,
    recaptchaToken,
    setRecaptchaToken,
  } = useContext(AuthContext);

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [resendRecaptchaModal, setResendRecaptchaModal] = useState(false);
  const { recaptchaRef } = useRecaptcha();

  const handleChange = (code) => {
    setCode(code);
  };

  // Receive OTP function
  const requestVerificationCode = useCallback(async () => {
    if (cooldown) return;

    setCooldown(true);
    if (verificationType === "phone") {
      await PhoneClient.getOTP({
        mobileNumber: emailOrPhone,
        token: recaptchaToken,
      });
    } else if (verificationType === "email") {
      await EmailClient.requestCode({
        email: emailOrPhone,
        token: recaptchaToken,
      });
    }
    setResendRecaptchaModal(false);

    // Start countdown
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          setCooldown(false);
          setTimeLeft(60); // Reset timer for next click
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
  }, [recaptchaToken, cooldown, verificationType]);

  useEffect(() => {
    setLoading(true);
    requestVerificationCode();
  }, [verificationType, recaptchaToken]);

  //verify OTP function
  const verifyOTP = async () => {
    if (verificationType === "phone") {
      const data = await PhoneClient.verifyOTP({
        mobileNumber: emailOrPhone,
        code,
      });

      if (data.message === "verified" || data.status == "0") {
        setError(false);
        handleVerify(code);
      } else {
        setError(code.length === 0 ? "noData" : "wrongData");
      }
    } else if (verificationType === "email") {
      const data = await EmailClient.verifyEmail({
        email: emailOrPhone,
        verificationCode: code,
      });

      if (data.message === "verified" || data.status == "0") {
        setError(false);
        handleVerify(code);
      } else {
        setError(code.length === 0 ? "noData" : "wrongData");
      }
    }
  };

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/assets/account_bg.jpg"
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Modal
        show={resendRecaptchaModal}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setResendRecaptchaModal(false)}
        centered
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
            onChange={(token) => {
              setRecaptchaToken(token);
              //   requestVerificationCode();
            }}
          />
        </div>
      </Modal>
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white">
              <form className="container-lg">
                <Row className="mb-3 text-center justify-content-center p-5">
                  <Col sm={12} md={6}>
                    <Link to={`${language ? "/en" : "/th"}/user/signup`}>
                      <BsArrowLeftShort className="form_ico_back" />
                    </Link>

                    <h2 className="mb-4">
                      {language ? "Verification" : "การยืนยันตัวตน"}
                    </h2>

                    {language ? (
                      <p>
                        Your verification code has been sent to{" "}
                        <span className="txt-bold">{emailOrPhone}</span>
                      </p>
                    ) : (
                      <p>
                        รหัสสำหรับการยืนยันตัวตนได้ถูกส่งไปยัง{" "}
                        <span className="txt-bold">{emailOrPhone}</span>แล้ว
                      </p>
                    )}

                    <section className="mb-3">
                      <OtpInput
                        containerStyle={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        value={code}
                        onChange={handleChange}
                        separator={<span style={{ width: "10px" }}></span>}
                        isInputNum={verificationType !== "email"}
                        shouldAutoFocus={true}
                        inputStyle={{
                          border: "1px solid",
                          borderColor:
                            "transparent transparent black transparent",
                          width: "40px",
                          height: "40px",
                          fontSize: "12px",
                          color: "#000",
                          fontWeight: "400",
                          caretColor: "blue",
                          marginBottom: "20px",
                        }}
                        focusStyle={{
                          outline: "none",
                        }}
                        numInputs={6}
                      />

                      {error === "noData" && (
                        <p className="errormsg">Please fill in 6 digits OTP</p>
                      )}

                      {error === "wrongData" && (
                        <p className="errormsg">
                          please make sure you enter the correct verification
                          code
                        </p>
                      )}

                      <div
                        className="btn d-block mb-3 btn-login btn-main w-100"
                        onClick={verifyOTP}
                      >
                        {language ? "Verify" : "ยืนยัน"}
                      </div>

                      <span className="d-block text-center txt-20 mb-4 mt-5">
                        {language
                          ? "Did not receive the code?"
                          : "ไม่ได้รับรหัสยืนยันใช่หรือไม่"}
                        <br />
                        <span className="">
                          <div
                            className={`txt-20 link_ link_red mx-auto mx-md-0 ${
                              cooldown ? "disabled" : ""
                            }`}
                            style={{
                              cursor: cooldown ? "not-allowed" : "pointer",
                            }}
                            {...(!cooldown
                              ? { onClick: () => setResendRecaptchaModal(true) }
                              : null)}
                          >
                            {cooldown
                              ? `${timeLeft}s ${
                                  language ? "Resend" : "ส่งใหม่"
                                }`
                              : language
                              ? "Resend"
                              : "ส่งใหม่"}
                          </div>
                          <span className="mx-1">
                            {language ? "or" : "หรือ"}
                          </span>
                          <Link
                            to={`/${language ? "en" : "th"}/user`}
                            className="txt-20 link_ link_red mx-auto mx-md-0"
                          >
                            {language
                              ? "try a different login method"
                              : "เข้าสู่ระบบด้วยวิธีอื่น"}
                          </Link>
                        </span>
                      </span>
                    </section>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default VerificationForm;
