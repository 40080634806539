import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap"; //react-bootstrap
import { BsArrowLeftShort } from "react-icons/bs";
import { SHA256 } from "crypto-js";
import { LanguageContext } from "../../contexts/LanguageContext";
import { Loader, FormField } from "../../components/";
import { updatePassword } from "../../api/password";
import { resetPassword } from "../../api/sms";
import "../../components/login/LoginForm.css";

function ResetPassword() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate(); //Redirect
  const { language } = useContext(LanguageContext);

  const [passwordField, setPasswordField] = useState("");

  //4. Define custom form submit action
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const searchParams = new URLSearchParams(window.location.search);

      const hashPassword = SHA256(passwordField);
      const token = searchParams.get("token");
      const method = searchParams.get("method");

      if (method === "email") {
        const { data } = await updatePassword({
          token: token,
          Password: hashPassword.toString(),
        });

        if (data.Status === "200") {
          navigate((language ? "/en" : "/th") + "/user");
        } else {
          alert("Please try again.");
        }
        return;
      }

      if (method === "phone") {
        const response = await resetPassword({
          token: token,
          newPassword: hashPassword.toString(),
        });

        if (response.status === 200) {
          navigate((language ? "/en" : "/th") + "/user");
        } else {
          alert(response.message);
        }
        return;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/account_bg.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {loading && <Loader status={loading} />}
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white">
              <form className="container-lg">
                <Row className="mb-3 text-center justify-content-center">
                  <Col sm={12} md={6}>
                    <Link to={`/${language ? "en" : "th"}/user`}>
                      <BsArrowLeftShort className="form_ico_back" />
                    </Link>

                    <h2 className="mb-4">
                      {language ? "Reset Password" : "รีเซ็ตรหัสผ่าน?"}
                    </h2>
                    <p className="text-center mb-4 fs-6">
                      {language
                        ? "Please enter your new password."
                        : "กรุณาใส่รหัสผ่านใหม่ของคุณ"}
                    </p>

                    <section>
                      <FormField
                        value={passwordField}
                        setValue={setPasswordField}
                        labelText=""
                        placeholder={language ? "New Password" : "รหัสผ่านใหม่"}
                        fieldType="password"
                        inputType="New Password"
                      />

                      <button
                        className="btn d-block mb-3 btn-login btn-main w-100"
                        onClick={submitForm}
                      >
                        {language ? "Reset Password" : "รีเซ็ตรหัสผ่าน"}
                      </button>
                    </section>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPassword;
