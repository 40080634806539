import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./LoginForm.css";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AppContext } from "../../contexts/AppContext";
import sha256 from "crypto-js/sha256";
import { login } from "../../api/auth";
import * as yup from "yup";
import yupPassword from "yup-password";
import { useValidation } from "../../hooks/validation";
import FormField from "../form/FormField";
import { Loader } from "../";

const LoginForm = () => {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { setTitle, setDescription } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState(false);

  yupPassword(yup);

  const phoneRegExp = /^(?:[0-9] ?){6,14}[0-9]$/;

  let yupMethod;
  let userMethodValue = document.getElementById("EmailorPhoneNumber");
  if (userMethodValue !== null) {
    if (
      userMethodValue.value.includes("@") ||
      /[a-zA-Z]/g.test(userMethodValue.value)
    ) {
      yupMethod = yup.string().email().required();
    } else {
      yupMethod = yup
        .string()
        .required()
        .matches(
          phoneRegExp,
          "Phone number is not valid Please add your country code. etc: '6' infront of your phone number"
        );
    }
  }

  const schema = yup.object().shape({
    email: yupMethod,
    password: yup
      .string()
      .required()
      .min(
        8,
        "Password must be 8 characters long and contain a combination of alphanumeric characters and special symbols."
      )
      .minNumbers(1, "Password must be contain one numeric characters"),
  });

  const { errors, useField } = useValidation(schema);

  const { emailField, setEmailField } = useField("email");
  const { passwordField, setPasswordField } = useField("password");

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      let hashDigest = sha256(passwordField);
      let data = { UserName: emailField, Password: hashDigest.toString() };
      setLoading(true);
      const { status, token } = await login({
        username: data.UserName,
        password: data.Password,
      });
  
      if (status === 200 && token) {
        setErrormsg(false);
        localStorage.setItem("accessToken", token);
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 1000);
      } else {
        setErrormsg(!errormsg);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTitle("Login now to start shopping!")
    setDescription("")
  }, [])

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/account_bg.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {loading && <Loader status={loading} />}
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white">
              <form className="container-lg data_wrap">
                <Row className="mb-3 text-center">
                  <Col>
                    {language ? (
                      <h2 className="mb-4">
                        Welcome! Please Login to continue.
                      </h2>
                    ) : (
                      <h2 className="mb-4">
                        ยินดีต้อนรับ! เข้าสู่ระบบเพื่อดำเนินการต่อ
                      </h2>
                    )}
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col sm={12} md={9} className="px-4 data_wrap">
                    <section>
                      <FormField
                        value={emailField}
                        setValue={setEmailField}
                        error={errors.email}
                        labelText=""
                        placeholder="Email or Phone Number"
                        fieldType={
                          emailField.includes("@") ? "email" : "mobile"
                        }
                        inputType="Email or Phone Number"
                      />
                      <FormField
                        value={passwordField}
                        setValue={setPasswordField}
                        error={errors.password}
                        labelText=""
                        placeholder="Password"
                        fieldType="Password"
                        inputType="Password"
                      />
                      {errormsg && (
                        <p className="errormsg">Account not found!</p>
                      )}
                      
                      <Link
                        to="forgot-password"
                        className="d-table float-end mb-4 link_ link_blue"
                      >
                        {language ? "Forgot your password?" : "ลืมรหัสผ่าน?"}
                      </Link>
                      <div
                        className="btn d-block mb-3 btn-login btn-main w-100"
                        onClick={submitForm}
                      >
                        {language ? "Login" : "เข้าสู่ระบบ"}
                      </div>
                      <span className="d-md-flex justify-content-center txt-20 mb-4">
                        {language ? "New member?" : "เป็นสมาชิกใหม่?"}

                        <Link
                          to="signup"
                          className="txt-20 link_ link_red mx-1"
                        >
                          {language ? "Register now" : "สมัครสมาชิก"}
                        </Link>
                      </span>
                      
                    </section>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginForm;
