import { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { HiChevronLeft } from "react-icons/hi";
import Parser from "html-react-parser";
import { getPromotionByID } from "../../api/promotion";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AppContext } from "../../contexts/AppContext";
import { Loader } from "../../components/";
import "../../App.css";

const Promotion = () => {
  const [currentPromoList, setCurrentPromoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pid } = useParams();
  const { language } = useContext(LanguageContext);
  const { setTitle, setDescription } = useContext(AppContext);

  useEffect(() => {
    const fetchPromoItem = async () => {
      try {
        setLoading(true);
        const { status, data } = await getPromotionByID(atob(pid));
        if (status !== 200) return;
        setCurrentPromoList(data);
        setTitle(data.promotionName);
        setDescription(data.description);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPromoItem();
  }, []);

  const hash = JSON.parse(localStorage.getItem("pagePathname"));

  return (
    <>
      {loading && <Loader status={loading} />}
      <Container>
        <Row className="py-3 py-md-5">
          <Col xs={12} className="mb-2 mb-md-4">
            <Link
              to={`/${language ? "en" : "th"}/promotions`}
              className="link_ d-table bg-grey-5 me-0 me-md-3 p-2 mb-4 border-rounded"
            >
              <div className="d-flex align-items-center">
                <span className="me-2 bg-yellow-1 box-40 d-flex align-items-center justify-content-center border-rounded">
                  <HiChevronLeft className="hiCheveronLeft" />
                </span>
                <span className="px-2 txt-bold">
                  {language ? "Back to Promotion" : "กลับไปที่โปรโมชั่น"}
                </span>
              </div>
            </Link>
          </Col>

          <Col xs={12}>
            <>
              <small className="d-table mb-3 bg-red-1 txt-15 text-white p-1 px-2">
                {currentPromoList?.startDateTime}
              </small>

              {currentPromoList.length !== 0 && (
                <div className="content_img d-block">
                  {Parser(currentPromoList?.description)}
                </div>
              )}
            </>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Promotion;
