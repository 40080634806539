import axios from "axios";

const authClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/meta`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getMetaData = async () => {
  const { data } = await authClient.get("/");
  return data;
};
