import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LanguageContext } from "./contexts/LanguageContext";
import { getAccessToken } from "./utils/jwt";

const PrivateRoute = () => {
  const language = useContext(LanguageContext)
  const accessToken = getAccessToken();

  return accessToken ? <Outlet /> : <Navigate to={`/${language ? "en" : "th"}/user`}></Navigate>;
}

export default PrivateRoute;
