import { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TagManager from "react-gtm-module";
import LazyLoad from "react-lazy-load";
import { PromotionTabSec } from "../../components/";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AppContext } from "../../contexts/AppContext";
import "../cart/Cart.css";

const Promotions = () => {
  const tagManagerArgs = {
    gtmId: "GTM-MMFHWKW",
    events: {
      view_promotion: "view_promotion",
    },
  };
  TagManager.initialize(tagManagerArgs);

  const { language } = useContext(LanguageContext);
  const { setTitle, setDescription } = useContext(AppContext);
  let globalIMG = process.env.PUBLIC_URL;

  useEffect(() => {
    setTitle("Promotions")
    setDescription("");
  }, [])

  return (
    <div className="promotion_page px-2 px-md-5">
      <Container fluid="xxl" className="mb-5">
        <Row className="data_wrap pt-5 px-2 px-md-5">
          <Col xs={12}>
            <div className="d-flex align-items-center">
              <span>
                <LazyLoad threshold={0}>
                  <img
                    className="d-block img-fluid img-70"
                    src={globalIMG + "/assets/ico_page_promo.png"}
                    alt="Promotions"
                  />
                </LazyLoad>
              </span>
              <h2 className="px-2 txt-bold txt-super-bold mb-0">
                {language ? "Promotions" : "โปรโมชั่น"}
              </h2>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid="xxl" className="mb-5">
        <Row className="px-2 px-md-5">
          <Col>
            <PromotionTabSec />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Promotions;
