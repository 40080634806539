import { useContext, useEffect } from "react";
import LazyLoad from "react-lazy-load";
import { NavLink } from "react-router-dom";
import { Col } from "react-bootstrap";
import { nanoid } from "nanoid"
import { LanguageContext } from "../../contexts/LanguageContext";
import { AppContext } from "../../contexts/AppContext";
import { convertToURLParams } from "../../utils/string";
import "./ProductTags.css";

/**
 * The component for rendering a single Product
 * @param {Object} props - The props for ProductCard component
 * @param {Object} props.product - The product object
 * @returns {JSX.Element} - The ProductCard Element
 */
const ProductCard = ({ product, categoryName }) => {
  const { language } = useContext(LanguageContext);
  
  const displayProductName = (productName) => {
    const enName = productName?.EN;
    const thName = productName?.TH;

    let displayName = language ? enName || thName : thName || enName;

    return displayName.length > 40
      ? `${displayName.slice(0, 40)}...`
      : displayName;
  };

  return (
    <Col xs={6} lg={4} xl={3} xxl={2} className="mb-4 prod-item-col" key={nanoid()}>
      <Col className="data_list">
        <div className="data_box h-100 d-flex flex-column justify-content-between">
          <LazyLoad height={247} threshold={0} className="img-box">
            <NavLink to={`${language ? "/en" : "/th"}/${convertToURLParams(categoryName)}/${convertToURLParams(product.name[language ? "EN" : "TH"])}-i.${btoa(product.id)}`}>
            <img
              src={typeof product.image === "string" ? product.image : product.image[0].original}
              alt={product.name?.EN || product.name.TH}
              className="img-fluid img-data"
            />
            </NavLink>
          </LazyLoad>

          {
            !!product.label && (!!product.label.EN || !!product.label.TH)
            ? <span className="new_label">{language ? "New" : "ใหม่"}</span>
            : null
          }

          <div className="p-2 p-lg-3 product-item-info">
            <hr />

            <h3 className="txt-20 txt-20-div prod-item-name">
              {displayProductName(product.name)}
            </h3>

            <div className="d-flex align-items-end mb-2 price-block">
              {!product.discountRate || product.discountRate === "0%" ? (
                <span className="text-price txt-30 mb-3 text-less-bold">
                  <sub>THB</sub>
                  {product.originalPrice}
                </span>
              ) : (
                <div className="d-block d-md-flex align-items-md-baseline">
                  <span className="text-price text-red-1 txt-30 mb-2 me-2 text-less-bold">
                    <sub>THB</sub>
                    {product.retailPrice || product.discountedPrice}
                  </span>
                  <span className="text-price text-grey-1 txt-20 mb-2 text-normal ">
                    <sub className="txt-stroke text-normal">
                      THB
                    </sub>
                    <span className="txt-stroke text-less-bold">
                      {product.originalPrice}
                    </span>
                  </span>
                </div>
              )}
            </div>

            <NavLink to={`${language ? "/en" : "/th"}/${convertToURLParams(categoryName)}/${convertToURLParams(product.name[language ? "EN" : "TH"])}-i.${btoa(product.id)}`} className="btn btn-card-sec w-100">
              {language ? "View Details" : "ดูรายละเอียด"}
            </NavLink>
          </div>
        </div>
      </Col>
    </Col>
  )
}

export default ProductCard;