import axios from "axios";

const countryStateClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/CountryState`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const getCountryState = async () => {
  const { data } = await countryStateClient.get("/");

  return data;
};
