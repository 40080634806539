import { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { AccountTab, PurchaseHistoryTab, RefundHistoryTab } from "../../components";
import { useLocation } from "react-router-dom";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AppContext } from "../../contexts/AppContext";

const Account = () => {
  const [tabTarget, setTabTarget] = useState("manage");
  const { language } = useContext(LanguageContext)
  const { setTitle, setDescription } = useContext(AppContext);
  const location = useLocation();
  const hash = location.hash;

  useEffect(() => {
    if (hash === "#history") setTabTarget("purchaseHistory")
    setTitle("")
    setDescription("")
  }, [hash])

  return (
    <Container>
      <Row className="mb-2 mb-md-4">
        <Col className="p-0">
          <div className="py-5 AccTabSec">
            <Tabs
              activeKey={tabTarget} 
              onSelect={(e) => setTabTarget(e)}
              id="fill-tab-example"
              letiant="tabs"
              className="justify-content-center align-items-end"
              justify
            >
              <Tab eventKey="manage" title={language ? "Manage My Account" : "จัดการบัญชีของฉัน"} className="p-3 p-md-5">
                <AccountTab />
              </Tab>
              <Tab eventKey="purchaseHistory" title={language ? "Order History" : "ประวัติการสั่งซื้อ"} className="p-3 p-md-5">
                <PurchaseHistoryTab />
              </Tab>
              <Tab eventKey="refundHistory" title={language ? "Refund History" : "ประวัติการคืนเงิน"} className="p-3 p-md-5">
                <RefundHistoryTab />
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Account;
