import { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import clsx from "clsx";
import {
  getRefundConditions,
  getRefundReasons,
  getRefundResolutions,
  getRefundOrdersBankDetails,
  getRefundOrdersById,
  updateRefundOrder,
  updateRefundOrderBankDetails,
  addRefundOrderMessage,
  addRefundOrdersScreenShots,
} from "../../api/refund";
import { Loader } from "../../components/";
import { useFileDownload } from "../../hooks/fileDownload";
import { useFileUpload } from "../../hooks/fileUpload";
import { LanguageContext } from "../../contexts/LanguageContext";
import { parseDate } from "../../utils/date";
import "./RefundDetails.css";

const conditionNameTH = {
  "Partially Broken": "เสียหายบางส่วน",
  "Fully Broken": "เสียหายทั้งหมด",
};

const reasonNameTH = {
  Damaged: "สินค้าเสียหาย",
  "Wrong Product": "สินค้าผิด",
  "Partially Broken": "สินค้าเสียหายบางส่วน",
};

const resolutionNameTH = {
  "Partial Refund": "ขอคืนเงินบางส่วน",
  "Full Refund": "ขอคืนเงินเต็มจำนวน",
  Return: "ขอคืนสินค้า",
};

const RefundDetails = () => {
  const { id: rmaID } = useParams();
  const { language } = useContext(LanguageContext);
  const { downloadFile } = useFileDownload();
  const { files, onFileUpload, filePreviews, serializeFiles } = useFileUpload();

  const [refund, setRefund] = useState(null);
  const [refundBankDetail, setRefundBankDetail] = useState(null);
  const [refundConditions, setRefundConditions] = useState([]);
  const [refundReasons, setRefundReasons] = useState([]);
  const [refundResolutions, setRefundResolutions] = useState([]);
  const [refundMessages, setRefundMessages] = useState([]);
  const messageContainerRef = useRef(null);
  const newMessageRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!rmaID) return;

    const fetchRefundOrders = async () => {
      const refund = await getRefundOrdersById(atob(rmaID));
      if (!refund) return;

      setRefund(refund);

      const mergedMessages = [
        ...refund.customerMessages,
        ...refund.adminMessages,
      ];
      const sortedMessages = mergedMessages.sort((a, b) => {
        const timestampA = parseDate(a.refundMessageTimestamp);
        const timestampB = parseDate(b.refundMessageTimestamp);

        return timestampA.getTime() - timestampB.getTime();
      });
      setRefundMessages(sortedMessages);
    };

    const fetchRefundBankDetails = async () => {
      const refundBankDetail = await getRefundOrdersBankDetails(atob(rmaID));

      if (!refundBankDetail) return;

      setRefundBankDetail(refundBankDetail);
    };

    const fetchRefundConditions = async () => {
      const refundConditions = await getRefundConditions();

      if (!refundConditions) return;

      setRefundConditions(refundConditions);
    };

    const fetchRefundReasons = async () => {
      const refundReasons = await getRefundReasons();

      setRefundReasons(refundReasons);
    };

    const fetchRefundResolutions = async () => {
      const refundResolutions = await getRefundResolutions();

      setRefundResolutions(refundResolutions);
    };

    setIsLoading(true);
    Promise.all([
      fetchRefundOrders(),
      fetchRefundBankDetails(),
      fetchRefundConditions(),
      fetchRefundReasons(),
      fetchRefundResolutions(),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [rmaID]);

  const handleRefundItemConditionChange = (e, refundItemID) => {
    const selectedID = e.target.value;
    const selectedCondition = refundConditions.find(
      (condition) => condition.conditionID.toString() === selectedID
    );

    setRefund({
      ...refund,
      refundItems: refund.refundItems.map((item) =>
        item.refundItemID === refundItemID
          ? {
              ...item,
              itemCondition: {
                id: selectedCondition.conditionID,
                name: selectedCondition.conditionName,
              },
            }
          : item
      ),
    });
  };

  const handleRefundItemQuantityChange = (e, refundItemID) => {
    const input = e.target;
    const newQuantity = Number(input.value);

    if (newQuantity === 0) {
      input.value = 1;
      return;
    }

    if (newQuantity > input.max) {
      input.value = input.max;
      return;
    }

    setRefund({
      ...refund,
      refundItems: refund.refundItems.map((item) =>
        item.refundItemID === refundItemID
          ? { ...item, refundItemQuantity: newQuantity }
          : item
      ),
    });
  };

  const handleRefundReasonChange = (e) => {
    const selectedID = e.target.value;
    const selectedReason = refundReasons.find(
      (reason) => reason.reasonID.toString() === selectedID
    );

    setRefund({
      ...refund,
      refundOrderReason: {
        id: selectedReason.reasonID,
        name: selectedReason.reasonName,
      },
    });
  };

  const handleRefundResolutionChange = (e) => {
    const selectedID = e.target.value;
    const selectedResolution = refundResolutions.find(
      (resolution) => resolution.resolutionID.toString() === selectedID
    );

    setRefund({
      ...refund,
      refundOrderResolution: {
        id: selectedResolution.resolutionID,
        name: selectedResolution.resolutionName,
      },
    });
  };

  const handleSendMessage = async () => {
    const newMessage = newMessageRef.current.value;
    if (!newMessage) return;

    try {
      setIsLoading(true);
      const response = await addRefundOrderMessage(atob(rmaID), newMessage);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      window.location.reload();
    }
  };

  const handleSubmit = async () => {
    const promises = [];

    if (!refund) return;
    if (!rmaID || typeof rmaID === "object") return;

    const updateRefundOrderPromise = updateRefundOrder({
      refundDescription: refund.refundOrderDescription ?? "No description",
      refundReason: Number(refund?.refundOrderReason.id) || 0,
      refundStatus: Number(refund?.refundOrderStatus.id) || 1,
      refundResolution: Number(refund?.refundOrderResolution.id) || 0,
      rmaManager: refund?.refundManagerID || 0,
      orderID: Number(refund.orderID) ?? 0,
      orderItems: refund.refundItems.map((item) => ({
        orderItemID: item.orderItemID,
        refundItemQuantity: item.refundItemQuantity,
        refundItemCondition: item.itemCondition.id,
      })),
    });
    promises.push(updateRefundOrderPromise);

    const updateBankDetailsPromise = refundBankDetail
      ? updateRefundOrderBankDetails(atob(rmaID), refundBankDetail)
      : Promise.resolve();
    promises.push(updateBankDetailsPromise);

    const serializedFiles = await serializeFiles(files);
    if (serializedFiles) {
      serializedFiles.forEach(({ content }) => {
        const uploadFilePromise = addRefundOrdersScreenShots({
          refundOrderID: atob(rmaID),
          screenshot: content,
        });

        promises.push(uploadFilePromise);
      });
    }

    setIsLoading(true);
    Promise.all(promises)
      .then((responses) => {
        window.location.reload();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <Row className="mb-2 mb-md-4">
        <Col>
          {isLoading && <Loader status={isLoading} />}
          {refund && (
            <div className="page">
              <h1 className="page-title">
                {language ? "Refund Details" : "รายละเอียดคำขอคืนสินค้า"}
              </h1>

              <Link to={`/${language ? "en" : "th"}/orders/${btoa(refund.orderID)}`} className="order-id">
                {language ? "Order ID" : "รหัสคำสั่งซื้อ"}: {refund.orderID}
              </Link>

              <div className="flex-between">
                <p className="refund-id">
                  {language ? "Refund ID" : "รหัสคืนเงิน"}:{" "}
                  {refund.refundOrderID}
                </p>

                <div
                  className={clsx(
                    "refund-status",
                    refund.refundOrderStatus.name.toLowerCase() === "completed"
                      ? "completed"
                      : refund.refundOrderStatus.name.toLowerCase() ===
                        "rejected"
                      ? "rejected"
                      : "pending"
                  )}
                >
                  <span>{refund.refundOrderStatus.name}</span>
                </div>
              </div>

              <div className="refund-items">
                {refund.refundItems.map(
                  ({
                    refundItemID,
                    itemImage,
                    refundItemSKUName,
                    itemCondition,
                    refundItemQuantity,
                    itemQuantity,
                  }) => (
                    <div className="refund-item" key={refundItemID}>
                      <img className="item-image" src={itemImage} alt="" />

                      <div className="item-details">
                        <p className="item-name">{refundItemSKUName}</p>

                        <div className="item-form">
                          <div className="item-condition">
                            <label htmlFor="item-condition">
                              {language ? "Item Condition" : "ภาพของสินค้า"}
                            </label>
                            <div className="select-container">
                              <select
                                onChange={(e) =>
                                  handleRefundItemConditionChange(
                                    e,
                                    refundItemID
                                  )
                                }
                                value={itemCondition.id}
                                id="refund-status"
                                name="refund-status"
                              >
                                {refundConditions.map((condition) => (
                                  <option
                                    key={condition.conditionID}
                                    value={condition.conditionID}
                                  >
                                    {language
                                      ? condition.conditionName
                                      : conditionNameTH[
                                          condition.conditionName
                                        ]}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="return-quantity">
                            <label htmlFor="return-quantity">
                              {language ? "Return Quantity" : "จำนวน"}
                            </label>
                            <div className="input-container">
                              <input
                                onChange={(e) =>
                                  handleRefundItemQuantityChange(
                                    e,
                                    refundItemID
                                  )
                                }
                                value={refundItemQuantity || 1}
                                min={1}
                                max={itemQuantity}
                                type="number"
                                name="return-quantity"
                                id="return-quantity"
                              />
                              <span>/ {itemQuantity}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>

              <div className="refund-description">
                <label htmlFor="refund-description">
                  {language
                    ? "Refund / Return Description"
                    : "รายละเอียดการขอคืนเงิน / คืนสินค้า"}
                </label>
                <div className="textarea-container">
                  <textarea
                    onChange={(e) =>
                      setRefund({
                        ...refund,
                        refundOrderDescription: e.target.value,
                      })
                    }
                    value={refund.refundOrderDescription}
                    name="refund-description"
                    id="refund-description"
                    cols="30"
                    rows="5"
                  ></textarea>
                </div>
              </div>

              <div className="defect-images">
                <p>{language ? "Defect Images" : "ภาพข้อบกพร่อง"}</p>

                <ul className="screenshots">
                  {refund.screenshots
                    .filter((screenshot) =>
                      Boolean(screenshot.refundItemDefectImage)
                    )
                    .map(({ refundItemID, refundItemDefectImage }) => (
                      <div key={refundItemID} className="screenshot">
                        <img
                          src={`data:image/png;base64,${refundItemDefectImage}`}
                        />
                      </div>
                    ))}
                  {files.length > 0 && filePreviews}
                  <label htmlFor="file-upload" className="add-screenshot">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 9a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 9z" />
                      <path
                        fillRule="evenodd"
                        d="M9.344 3.071a49.52 49.52 0 015.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 01-3 3h-15a3 3 0 01-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 001.11-.71l.822-1.315a2.942 2.942 0 012.332-1.39zM6.75 12.75a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm12-1.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <span>{language ? "add photos" : "เพิ่มรูปถ่าย"}</span>

                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={onFileUpload}
                    />
                  </label>
                </ul>
              </div>

              <div className="refund-reason">
                <label htmlFor="refund-reason">
                  {language
                    ? "Refund / Return Reason"
                    : "เหตุผลในการขอคืนเงิน / คืนสินค้า"}
                </label>
                <div className="select-container">
                  <select
                    onChange={handleRefundReasonChange}
                    value={refund.refundOrderReason.id}
                    id="refund-reason"
                    name="refund-reason"
                  >
                    <option className="disabled-option" value={0} disabled>
                      ({language ? "No reason" : "ไม่มีเหตุผล"})
                    </option>
                    {refundReasons.map((reason) => (
                      <option key={reason.reasonID} value={reason.reasonID}>
                        {language
                          ? reason.reasonName
                          : reasonNameTH[reason.reasonName]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="refund-resolution">
                <label htmlFor="refund-resolution">
                  {language
                    ? "Refund / Return Resolution"
                    : "รูปแบบการคืนเงิน / คืนสินค้าที่ต้องการ"}
                </label>
                <div className="select-container">
                  <select
                    onChange={handleRefundResolutionChange}
                    value={refund.refundOrderResolution.id}
                    id="refund-resolution"
                    name="refund-resolution"
                  >
                    <option className="disabled-option" value={0} disabled>
                      ({language ? "No resolution" : "ไม่มีความละเอียด"})
                    </option>
                    {refundResolutions.map((resolution) => (
                      <option
                        key={resolution.resolutionID}
                        value={resolution.resolutionID}
                      >
                        {language
                          ? resolution.resolutionName
                          : resolutionNameTH[resolution.resolutionName]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <h3 className="bank-details-title">
                {language
                  ? "Bank Details"
                  : "รายละเอียดบัญชีธนาคารที่รับเงินคืน"}
              </h3>

              <div className="bank-details">
                <div className="bank-account-no">
                  <label htmlFor="bank-account-no">
                    {language ? "Bank Account No." : "เลขบัญชีธนาคาร"}
                  </label>
                  <div className="input-container">
                    <input
                      onChange={(e) =>
                        setRefundBankDetail({
                          ...refundBankDetail,
                          bankNumber: e.target.value,
                        })
                      }
                      value={refundBankDetail?.bankNumber}
                      type="text"
                      name="bank-account-no"
                      id="bank-account-no"
                    />
                  </div>
                </div>
                <div className="bank-name">
                  <label htmlFor="bank-name">
                    {language ? "Bank Name" : "ชื่อธนาคาร"}
                  </label>
                  <div className="input-container">
                    <input
                      onChange={(e) =>
                        setRefundBankDetail({
                          ...refundBankDetail,
                          bankName: e.target.value,
                        })
                      }
                      value={refundBankDetail?.bankName}
                      type="text"
                      name="bank-name"
                      id="bank-name"
                    />
                  </div>
                </div>
                <div className="account-name">
                  <label htmlFor="account-name">
                    {language ? "Account Name" : "ชื่อบัญชี"}
                  </label>
                  <div className="input-container">
                    <input
                      onChange={(e) =>
                        setRefundBankDetail({
                          ...refundBankDetail,
                          bankOwnerName: e.target.value,
                        })
                      }
                      value={refundBankDetail?.bankOwnerName}
                      type="text"
                      name="account-name"
                      id="account-name"
                    />
                  </div>
                </div>
              </div>

              <div className="messages">
                <p>
                  {language
                    ? "Messages"
                    : "ส่งข้อความสนทนากับฝ่ายลูกค้าสัมพันธ์"}
                </p>

                <div
                  id="messageContainer"
                  ref={messageContainerRef}
                  className="messages-container"
                >
                  {refundMessages.map((message) => (
                    <div
                      key={message.refundMessageID}
                      className={clsx(
                        "message-card",
                        message.type === "Customer" ? "customer" : "admin"
                      )}
                    >
                      <div className="message-info">
                        <span className="message-type">{message.type}</span>

                        <span className="message-time">
                          {message.refundMessageTimestamp}
                        </span>
                      </div>

                      <div
                        className={clsx(
                          "message",
                          message.type === "Customer" ? "customer" : "admin"
                        )}
                      >
                        <p>{message.refundMessage}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="new-message">
                  <div className="input-container">
                    <input
                      ref={newMessageRef}
                      type="text"
                      name="new-message"
                      id="new-message"
                      placeholder={
                        language ? "Enter your message..." : "กรอกข้อความของคุณ"
                      }
                    />
                  </div>
                  <button
                    onClick={handleSendMessage}
                    type="button"
                    className="send-button"
                  >
                    {language ? "Send" : "ส่ง"}
                  </button>
                </div>
              </div>

              <div className="attachments">
                <p>{language ? "Attachments" : "แนบภาพหน้าบุญชีธนาคาร"}</p>

                <div>
                  {refund.attachments.map((attachment) => (
                    <div className="attachment" key={attachment.id}>
                      <p>{attachment.fileName}</p>

                      <button
                        onClick={() =>
                          downloadFile(
                            attachment.fileName,
                            attachment.fileType,
                            attachment.content
                          )
                        }
                        className="download-button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>

                        <span>{language ? "Download" : "ดาวน์โหลด"}</span>
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="cta">
                <button onClick={handleSubmit} className="submit-button">
                  <span>{language ? "Submit" : "ส่งคำขอ"}</span>
                </button>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default RefundDetails;
