import { useState, useRef, useCallback, useEffect } from "react";
import axios from "axios";
import { getAccessToken } from "../utils/jwt";

/**
 * Creates an instance of axios client for reCaptcha API.
 * This client is configured with base URL, headers, and authorization token.
 *
 * @type {import('axios').AxiosInstance}
 */
const recaptchaClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/recaptcha`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

const useRecaptcha = () => {
  const [captchaToken, setCaptchaToken] = useState();
  const recaptchaRef = useRef(null);

  /**
   * Callback function to handle reCaptcha response.
   * It updates the captchaToken state with the received token.
   * If no token is provided, it sets an empty string.
   *
   * @callback handleRecaptcha
   * @param {string} token - The reCaptcha token received from the client-side.
   * @returns {void}
   */
  const handleRecaptcha = (token) => {
    setCaptchaToken(token || "");
  };

  /**
   * Function to verify the reCaptcha token.
   * It sends a POST request to the server with the token and returns the server response.
   *
   * @function verifyToken
   * @param {string} token - The reCaptcha token received from the client-side.
   * @returns {Promise<AxiosResponse<any>>} - A promise that resolves to the server response.
   * @throws Will throw an error if the request fails.
   */
  const verifyToken = async (token) => {
    const response = await recaptchaClient.post("/verify", { token });
    return response;
  };

  useEffect(() => {
    const refreshCaptcha = () => {
      if (recaptchaRef.current && captchaToken) {
        recaptchaRef.current.reset();
        setCaptchaToken("");
      }
    };

    let tokenRefreshTimeout = null;

    if (captchaToken) {
      tokenRefreshTimeout = setTimeout(refreshCaptcha, 110000); // 110 seconds
    }

    return () => {
      if (tokenRefreshTimeout) {
        clearTimeout(tokenRefreshTimeout);
      }
    };
  }, [captchaToken]);

  return {
    captchaToken,
    setCaptchaToken,
    recaptchaRef,
    handleRecaptcha,
    verifyToken,
  };
};

export default useRecaptcha;
