import { useEffect } from "react";

function DbdLogo() {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "dbd-init";
    script.src =
      "https://www.trustmarkthai.com/callbackData/initialize.js?t=19e45a9513-19-6-ebb7cc11610851445a23dc6bc70fa3798e83";

    document.body.appendChild(script);
  }, []);

  return <div id="Certificate-banners"></div>;
}

export default DbdLogo;
