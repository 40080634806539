import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";

export const LanguageContext = createContext("language");

export const LanguageProvider = ({ children }) => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState(false);

  useEffect(() => {
    const savedLanguage = JSON.parse(localStorage.getItem("language"));
    setLanguage(savedLanguage ?? false);
  }, [language]);

  const handleSwitchLanguage = () => {
    setLanguage(!language);
    localStorage.setItem("language", JSON.stringify(!language));
    const pathName = window.location.pathname;
    const newPathName = pathName.replace(language ? "/en" : "/th", language ? "/th" : "/en")
    navigate(newPathName);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        handleSwitchLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
