import axios from "axios";
import { getAccessToken } from "../utils/jwt";
import { checkAccessToken } from "./interceptor";

export const orderClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/Order`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

orderClient.interceptors.request.use(checkAccessToken);

export const getUserOrders = async ({ userID }) => {
  const { data } = await orderClient.get(`/users/${userID}`);
  const { Status, Orders } = data;

  return {
    status: Status,
    orders: Orders,
  };
};

export const getOrderStatus = async ({ orderID }) => {
  const { data } = await orderClient.get(`/${orderID}`);
  const { Status, Order } = data;

  return {
    status: Status,
    order: Order,
  };
};

export const updateOrder = async (payload) => {
  const { data } = await orderClient.put("/", payload);
  const { status, orderID } = data;

  return {
    status,
    orderID,
  };
};
