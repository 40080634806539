import { useContext, useEffect, useState } from "react";
import { PaginationGroup, ProductCard } from "../components"
import { SearchBarContext } from "../contexts/SearchBarContext";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DISPLAY_ITEMS_PER_PAGE as TOTAL_ITEMS_DISPLAY } from "../constants/config";
import { LanguageContext } from "../contexts/LanguageContext";
import { AppContext } from "../contexts/AppContext";

export const SearchResult = () => {
  const [pageNum, setPageNum] = useState(1);
  const { language } = useContext(LanguageContext);
  const { setTitle, setDescription } = useContext(AppContext);
  const { result } = useContext(SearchBarContext);

  useEffect(() => {
    setTitle("");
    setDescription("");
  }, [])

  return (
    <Container fluid="xxl">
      <Row className="data_wrap pt-5 pb-0">
        <div className="col">
          <div className="breadcrumb-wrap">
            <ul>
              <li>
                <Link to="/">
                  <img
                    alt="home"
                    src={process.env.PUBLIC_URL + "/assets/ico_homebtn.png"}
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Row>

      <Row className="d-flex data_wrap">
        {!result || result.length <= 0 ? (
          <span className="errormsg text-center txt-30">No Result Found</span>
        ) : (
          <>
            {result?.slice(
              TOTAL_ITEMS_DISPLAY * (pageNum-1),
              TOTAL_ITEMS_DISPLAY * pageNum).map((product, idx) => (
                <ProductCard key={idx} product={product} categoryName={product.category[language ? "EN" : "TH"]}/>
            ))}
          </>
        )}
      </Row>
      <Row className="mx-auto d-table mb-5">
        <Col xs={12}>
          <PaginationGroup
            pageNumber={pageNum} 
            setPageNumber={setPageNum} 
            totalItems={result.length} 
            totalItemsDisplay={TOTAL_ITEMS_DISPLAY} 
          />
        </Col>
      </Row>
    </Container>
  )
}
