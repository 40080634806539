import { useState, useEffect,  Suspense, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { useParams, useLocation } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { LanguageContext } from "../contexts/LanguageContext";
import { getDefaultPageContent } from "../api/cms";

const Default = () => {
  const { pid } = useParams();
  const { language } = useContext(LanguageContext);
  const { setTitle, setDescription } = useContext(AppContext);
  const location = useLocation();
  const pageLocated = location.pathname + location.search;
  const [data, setData] = useState([]);

  useEffect(() => {
    const getCMSDocument = async () => {
      const { documents } = await getDefaultPageContent({ language, pid });
      setData(documents);
      setTitle(documents.DefaultPage_title)
      setDescription("");
    };
    getCMSDocument();
  }, [language, pageLocated]);

  return (
    <Container>
      <Row className="py-3 py-md-5 mb-2 mb-md-4">
        <Col className="p-0">
          <Suspense fallback={<h1>...loading</h1>}>
            <div className="data_wrap">
              <h2 className="mb-4">{data.DefaultPage_title}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: data.DefaultPage_para }}
              />
            </div>
          </Suspense>
        </Col>
      </Row>
    </Container>
  );
}

export default Default;
