import { createContext, useState } from "react";

export const ShippingAddressContext = createContext(""); //context loginform
export const ShippingAddressProvider = ({ children }) => {
  //state
  const [shipList, setShipList] = useState([]);
  const [allShipList, setAllShipList] = useState([]);
  const [sortShipList, setSortShipList] = useState([]);
  const [selectedShipList, setSelectedShipList] = useState([]);
  //error
  const [error, setError] = useState(true);

  //...shiplist
  const createShipList = (customerAddressID) => {
    if (customerAddressID !== undefined || customerAddressID !== "") {
      setShipList(customerAddressID.customerAddressID);
    } else {
      setShipList("");
    }
  };

  const resetShipList = () => {
    setShipList("");
  };

  //...allshiplist
  const createAllShipList = (allData) => {
    setAllShipList(allData);
  };
  const create_sortAllShipList = (id) => {
    let selected_address = allShipList.find((list) => list.AddressID === id);
    let sortArr = allShipList.sort((a, b) => {
      if (
        selected_address.AddressID === a.AddressID ||
        selected_address.AddressID === b.AddressID
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    setAllShipList(sortArr);
    setSelectedShipList(selected_address);
  };

  return (
    <ShippingAddressContext.Provider
      value={{
        createShipList,
        shipList,
        resetShipList,
        createAllShipList,
        allShipList,
        setAllShipList,
        create_sortAllShipList,
        sortShipList,
        selectedShipList,
        error,
      }}
    >
      {children}
    </ShippingAddressContext.Provider>
  );
};
