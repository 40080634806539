import axios from "axios";
import { getAccessToken } from "../utils/jwt";
import { checkAccessToken } from "./interceptor";

export const invoiceClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_ENDPOINT}/invoice`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

invoiceClient.interceptors.request.use(checkAccessToken);

export const generateInvoice = async ({ orderID, language }) => {
  const {
    data: { status, data },
  } = await invoiceClient.post("/", { orderID, language });

  return {
    status: status,
    orderInvoice: data.orderInvoice,
    orderInvoiceNumber: data.orderInvoiceNumber,
  };
};
