import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import * as yup from "yup";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { LanguageContext } from "../../contexts/LanguageContext";
import { AuthContext } from "../../contexts/AuthContext";
import { AppContext } from "../../contexts/AppContext";
import { useValidation } from "../../hooks/validation";
import { Loader, FormField } from "../";
import useRecaptcha from "../../hooks/recaptcha";
import "./LoginForm.css";
import ReCAPTCHA from "react-google-recaptcha";

const SignUpForm = () => {
  const [loading, setLoading] = useState(false);
  //   const [currentTab, setCurrentTab] = useState("phone");
  const [currentTab, setCurrentTab] = useState("email");
  const { language } = useContext(LanguageContext);
  const { setTitle, setDescription } = useContext(AppContext);
  const { handleSignUp, setVerificationType, setRecaptchaToken } =
    useContext(AuthContext);
  const [hasAgreed, setHasAgreed] = useState(false);
  const { captchaToken, recaptchaRef, handleRecaptcha, verifyToken } =
    useRecaptcha();

  const handleHasAgreed = (e) => {
    setHasAgreed(e.target.checked);
  };

  let emailSchema = yup
    .string()
    .required("This field is required")
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email"
    );

  let phoneSchema = yup
    .string()
    .required("This field is required")
    .matches(/^0\d{8,9}$/, "Invalid Thai phone number.");

  const schema = yup.object().shape({
    email: emailSchema,
    phone: phoneSchema,
  });

  const { errors, useField, validateForm } = useValidation(schema);
  const { emailField, setEmailField } = useField("email");
  const { phoneField, setPhoneField } = useField("phone");

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const currentField = currentTab === "phone" ? phoneField : emailField;

    try {
      if (!captchaToken) {
        alert("Please complete the recaptcha.");
        return;
      }
      if (!hasAgreed) {
        alert("Please Agree to the Privacy Policy.");
        return;
      }
      const isFormValid = validateForm(currentTab, currentField);
      if (isFormValid) {
        setVerificationType(currentTab);
        setRecaptchaToken(captchaToken);
        handleSignUp(currentField);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onTabChange = (value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    setTitle("Sign up today!");
    setDescription("");
  }, []);

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/assets/account_bg.jpg"
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {loading && <Loader status={loading} />}
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white">
              <form className="container-lg">
                <Row className="mb-3 text-center justify-content-center p-5 px-0">
                  <Col sm={12} md={6}>
                    <Link to={`/${language ? "en" : "th"}/user`}>
                      <BsArrowLeftShort className="form_ico_back" />
                    </Link>

                    <h2 className="mb-4">
                      {language ? "Sign Up" : "ลงทะเบียน"}
                    </h2>

                    <section className="Signup-sec mb-3">
                      <Tabs
                        // defaultActiveKey="phone"
                        defaultActiveKey="email"
                        onSelect={onTabChange}
                        id="signup_tab"
                        variant="tabs"
                        className="signup_tabs justify-content-center justify-content-sm-start"
                        justify
                      >
                        {/* <Tab eventKey="phone" title="Phone">
                          <div
                            className={`input-group flex-nowrap mb-3 ${
                              errors.phone ? "border-focus" : ""
                            }`}
                          >
                            <input
                              type="text"
                              id="phone"
                              className="form-control"
                              onChange={(e) => setPhoneField(e.target.value)}
                              placeholder="Phone Number"
                            />
                            {errors.phone && (
                              <div className="">
                                <ExclamationCircleIcon
                                  className="img-20 text-red-1 input-absoulte"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                          {errors.phone && (
                            <p className="mt--2 errormsg">{errors.phone}</p>
                          )}
                          <div className="mb-3 d-flex justify-content-center">
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                              onChange={handleRecaptcha}
                            />
                          </div>
                          <div
                            className="btn d-block mb-3 btn-login btn-main w-100"
                            onClick={submitForm}
                          >
                            {language ? "Next" : "ถัดไป"}
                          </div>

                          <span className="d-block text-center txt-20 mb-4 mt-5">
                            <input
                              className="form-check-input mr-2"
                              type="checkbox"
                              id="checkboxAgree"
                              onChange={handleHasAgreed}
                            />
                            {language ? (
                              <>
                                By signing up, you agree to Mr. DIY's
                                <span className="d-md-flex justify-content-center mx-1">
                                  <div className="txt-20 link_ link_red mx-auto mx-md-0 mb-0">
                                    Terms of Service
                                  </div>
                                  <span className="mx-1">&</span>
                                  <div className="txt-20 link_ link_red mx-auto mx-md-0">
                                    <a
                                      href="https://shoponline.mrdiy.com/th/page/201/Privacy-and-Policy/"
                                      className="txt-20 link_ link_red"
                                    >
                                      Privacy Policy
                                    </a>
                                  </div>
                                </span>
                              </>
                            ) : (
                              <>
                                ตกลงและรับทราบ
                                <span className="d-md-flex justify-content-center mx-1">
                                  <div className="txt-20 link_ link_red mx-auto mx-md-0 mb-0">
                                    เงื่อนไขการให้บริการ
                                  </div>
                                  <span className="mx-1">และ</span>
                                  <div className="txt-20 link_ link_red mx-auto mx-md-0">
                                    <a
                                      href="https://shoponline.mrdiy.com/th/page/201/Privacy-and-Policy/"
                                      className="txt-20 link_ link_red"
                                    >
                                      นโยบายความเป็นส่วนตัว
                                    </a>
                                  </div>
                                </span>
                              </>
                            )}
                          </span>
                        </Tab> */}
                        <Tab eventKey="email" title="Email">
                          <FormField
                            value={emailField}
                            setValue={setEmailField}
                            error={errors.email}
                            labelText=""
                            placeholder="Email"
                            fieldType={"email"}
                            inputType="email"
                          />
                          <div className="mb-3 d-flex justify-content-center">
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                              onChange={handleRecaptcha}
                            />
                          </div>
                          <div
                            className="btn d-block mb-3 btn-login btn-main w-100"
                            onClick={submitForm}
                          >
                            {language ? "Next" : "ถัดไป"}
                          </div>

                          <span className="d-block text-center txt-20 mb-4 mt-5">
                            <input
                              className="form-check-input mr-2"
                              type="checkbox"
                              id="checkboxAgree"
                              onChange={handleHasAgreed}
                            />
                            {language ? (
                              <>
                                By signing up, you agree to Mr. DIY's
                                <span className="d-md-flex justify-content-center mx-1">
                                  <div className="txt-20 link_ link_red mx-auto mx-md-0 mb-0">
                                    Terms of Service
                                  </div>
                                  <span className="mx-1">&</span>
                                  <div className="txt-20 link_ link_red mx-auto mx-md-0">
                                    <a
                                      href="https://shoponline.mrdiy.com/th/page/201/Privacy-and-Policy/"
                                      className="txt-20 link_ link_red"
                                    >
                                      Privacy Policy
                                    </a>
                                  </div>
                                </span>
                              </>
                            ) : (
                              <>
                                ตกลงและรับทราบ
                                <span className="d-md-flex justify-content-center mx-1">
                                  <div className="txt-20 link_ link_red mx-auto mx-md-0 mb-0">
                                    เงื่อนไขการให้บริการ
                                  </div>
                                  <span className="mx-1">และ</span>
                                  <div className="txt-20 link_ link_red mx-auto mx-md-0">
                                    <a
                                      href="https://shoponline.mrdiy.com/th/page/201/Privacy-and-Policy/"
                                      className="txt-20 link_ link_red"
                                    >
                                      นโยบายความเป็นส่วนตัว
                                    </a>
                                  </div>
                                </span>
                              </>
                            )}
                          </span>
                        </Tab>
                      </Tabs>
                    </section>

                    <section></section>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUpForm;
