import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import "./LoginForm.css";
import * as yup from "yup";
import { LanguageContext } from "../../contexts/LanguageContext";
import { useValidation } from "../../hooks/validation";
import { Loader, FormField } from "../";
import axios from "axios";

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  let yupMethod;
  let userMethodValue = document.getElementById("EmailorPhoneNumber");

  if (userMethodValue !== null) {
    if (
      userMethodValue.value.includes("@") ||
      /[a-zA-Z]/g.test(userMethodValue.value)
    ) {
      yupMethod = yup.string().email().required("This field is required");
    }

    if (/[0-9]/g.test(userMethodValue.value)) {
      yupMethod = yup
        .string()
        .matches(
          phoneRegExp,
          "Phone number is not valid. Please add your country code. etc: '6' infront of your phone number"
        );
    }

    if (userMethodValue.value === "") {
      yupMethod = yup.string().required("This field is required");
    }
  }

  const schema = yup.object().shape({
    username: yupMethod,
  });

  const { errors, useField } = useValidation(schema);
  const { usernameField, setUsernameField } = useField("username");

  const submitForm = async (e) => {
    e.preventDefault();

    const { data } = await axios.post("http://localhost:8080/password/reset", {
      username: usernameField,
      regionCode: "MY",
    });
  };

  return (
    <div
      className="account_pg py-5"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/account_bg.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {loading && <Loader status={loading} />}
      <Container fluid className="my-3 my-md-4">
        <Row>
          <Col>
            <div className="form_login bg-white">
              <form className="container-lg">
                <Row className="mb-3 text-center justify-content-center">
                  <Col sm={12} md={6}>
                    <Link to={`/${language ? "en" : "th"}/user`}>
                      <BsArrowLeftShort className="form_ico_back" />
                    </Link>

                    <h2 className="mb-4">
                      {language
                        ? "Forgot Your Password?"
                        : "ลืมรหัสผ่านหรือไม่?"}
                    </h2>
                    <p className="text-start mb-4 fs-6">
                      {language
                        ? "Please enter your email address or phone number below to receive a password reset link."
                        : "โปรดป้อนที่อยู่อีเมลหรือหมายเลขโทรศัพท์ด้านล่างเพื่อรับลิงก์รีเซ็ตรหัสผ่าน"}
                    </p>

                    <section>
                      <FormField
                        value={usernameField}
                        setValue={setUsernameField}
                        error={errors.email}
                        labelText=""
                        placeholder={
                          language
                            ? "Email or Phone Number"
                            : "อีเมลหรือหมายเลขโทรศัพท์"
                        }
                        fieldType={
                          usernameField.includes("@") ? "email" : "mobile"
                        }
                        inputType="Email or Phone Number"
                      />

                      <button
                        className="btn d-block mb-3 btn-login btn-main w-100"
                        onClick={submitForm}
                      >
                        {language
                          ? "Reset My Password"
                          : "รีเซ็ตรหัสผ่านของฉัน"}
                      </button>
                    </section>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ForgotPassword;
