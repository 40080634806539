import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { LanguageContext } from "../../../contexts/LanguageContext";
import "./LanguageToggle.css";

function LanguageToggle() {
  const navigate = useNavigate();
  const { language, handleSwitchLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const pathName = window.location.pathname;
    const newPathName = language ? pathName.replace("/th", "/en") : pathName.replace("/en", "/th");
    navigate(newPathName);
  }, [language])

  return (
    <label className="switch ms-0 ms-lg-3">
      <input
        type="checkbox"
        id="langChk"
        value={language}
        checked={language}
        onChange={handleSwitchLanguage}
      />
      <span className="flag"></span>
      <span className="slider round">
        <span className="txt-bold lang_EN lang">EN</span>
        <span className="txt-bold lang_TH lang">TH</span>
      </span>
    </label>
  );
}

export default LanguageToggle;
